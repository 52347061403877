import { useEffect, useState, type FC } from 'react';
import "../../src/pdf.css";
import { Document, Page, StyleSheet, Text, View, Font, pdf , PDFViewer} from '@react-pdf/renderer';
import ReactDOMServer from 'react-dom/server';
import Html from 'react-pdf-html';
import { saveAs } from 'file-saver'



// import dynamic from "next/dynamic";




// const PDFViewer = dynamic(
//     () => import("@react-pdf/renderer").then((mod) => mod.PDFViewer),
//     {
//         ssr: false,
//         loading: () => <p>Loading...</p>,
//     },
// );


interface PDFViewProps {
    children: React.ReactNode;
    title: string
    print: boolean
    download: boolean,
    marginTop?: string,
    marginBottom?: string,
    orientation?: 'portrait' | 'landscape'
}

const PDFView: FC<PDFViewProps> = ({ children, title, print, download, marginTop, marginBottom, orientation = 'portrait' }) => {
    const [mounted, setMounted] = useState(false);
    useEffect(() => {
        // setTimeout(() => {
            setMounted(true)
        // }, 1000);
    }, []);

    useEffect(() => {
        if (!mounted) return
        if (download) {

            pdf(
                <Document title={title}>
                    <Page size="A4" style={styles.page}>
                        {children}
                    </Page>
                </Document>
            ).toBlob().then(blob => saveAs(blob, `${title}.pdf`)).then(() => {
                setTimeout(() => {
                    window.close()
                }, 300);
            })

        }

        if (print) {
            setTimeout(() => {
                window.print()
            }, 1000);
        }
    }, [mounted]);

    Font.register({
        family: 'dot',
        fonts: [
            {
                src: '/fonts/DotGothic16-Regular.ttf',
            },

        ],
    });

    const styles =  StyleSheet.create({
        page:  {
            backgroundColor: '#ffffff',
            marginTop: marginTop ?? "10mm",
            marginBottom: marginBottom ?? "10mm",
            fontSize: 10
        },
    });



    return (<PDFViewer  style={{ width: "100%", height: "100vh" }}>
        <Document title={title}>
            <Page orientation={orientation} size="A4" style={styles.page}>
                {children}
            </Page>
        </Document>
    </PDFViewer>);
}
export default PDFView;