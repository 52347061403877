import { Fragment, type FC, KeyboardEventHandler, CSSProperties, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';

interface ModalCustomProps {
    title?: string
    isOpen: boolean
    setIsOpen: (arg: boolean) => void
    onClose?: () => void
    onKeyUp?: KeyboardEventHandler<HTMLInputElement>
    onChange?: (arg: string) => void
    children: React.ReactNode
    footer?: React.ReactNode
    height?: number | string | null
    width?: number | string | null
    maxWidth?: number | string | null
    styles?: CSSProperties
}

const ModalCustom: FC<ModalCustomProps> = ({
    children,
    footer,
    title,
    isOpen,
    setIsOpen,
    height,
    width,
    onChange,
    onKeyUp,
    maxWidth,
    styles,
    onClose
}) => {
    const [isTablet, setisTablet] = useState(false);
    useEffect(() => {
        const userAgent = navigator.userAgent.toLowerCase();
        const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
        setisTablet(isTablet)
    }, []);
    return (<Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={(val: boolean) => {
            setIsOpen(val)
            if (onClose != null) onClose()
        }} >
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-black/25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel className="w-full flex flex-col max-w-2xl transform overflow-hidden rounded-2xl bg-form-dialog p-6 text-left align-middle shadow-xl transition-all " style={{ width: width ?? '100%', height: height ?? isTablet ?  'calc(100vh - 100px)' : 'calc(100vh - 400px)' , maxWidth: maxWidth ?? '42rem', ...styles}}>
                            <Dialog.Title
                                as="h3"
                                className="text-xl font-medium leading-6 text-gray-900"
                            >
                                {title}
                            </Dialog.Title>
                            <div className="flex-1 overflow-y-auto">
                                {children}
                            </div>
                            {footer}
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
        </Dialog>

    </Transition>);
}
export default ModalCustom;