import { Fragment, type FC, KeyboardEventHandler, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';

interface ModalSearchProps {
    placeholder?: string
    isOpen: boolean
    hideSearch?: boolean
    setIsOpen: (arg: boolean) => void
    onClose?: () => void
    onKeyUp?: KeyboardEventHandler<HTMLInputElement>
    onChange: (arg: string) => void
    children: React.ReactNode
    footer?: React.ReactNode
    height?: number | null
}

const ModalSearch: FC<ModalSearchProps> = ({
    children,
    footer,
    placeholder,
    hideSearch,
    isOpen,
    setIsOpen,
    height,
    onChange,
    onKeyUp,
    onClose
}) => {
    const [isTablet, setisTablet] = useState(false);
    useEffect(() => {
        const userAgent = navigator.userAgent.toLowerCase();
        const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
        setisTablet(isTablet)
    }, []);
    
    return (<Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative" style={{ zIndex: 2000 }} onClose={(val: boolean) => {
            setIsOpen(val)
            if (onClose != null) onClose()
        }} >
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-black/25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel className="w-full flex flex-col max-w-2xl transform overflow-hidden rounded-2xl bg-form-dialog  text-left align-middle shadow-xl transition-all " style={{ height: height  ?? isTablet ?  'calc(100vh - 100px)' : 'calc(100vh - 400px)' }}>
                            <Dialog.Title
                                as="h3"
                                className="text-xl font-medium leading-6 text-gray-900"
                            >
                                {hideSearch ? null :

                                    <div className=" w-full ">
                                        <div className="relative">
                                            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                                <MagnifyingGlassIcon
                                                    className=" h-5 w-5 text-violet-200 hover:text-violet-100"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                            <input
                                                
                                                autoComplete='off'
                                                type="search"
                                                id="default-search"
                                                className="block outline-none w-full p-4 ps-10 text-lg text-gray-900 border-0 rounded-full bg-transparent   "
                                                placeholder={placeholder ?? 'Search ...'}
                                                onChange={(val) => {
                                                    onChange(val.target.value)
                                                }}
                                                onKeyUp={onKeyUp}
                                            />
                                        </div>
                                    </div>
                                }
                            </Dialog.Title>
                            <div className="flex-1 overflow-y-auto">
                                {children}
                            </div>
                            {footer}
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
        </Dialog>

    </Transition>);
}
export default ModalSearch;