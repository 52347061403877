export interface PosReq {
    is_pinned?: boolean,
    search?: string,
    barcode?: string,
    product_category_id?: string,
}

export const paymentMethodsData = [
    {
        "name": "TUNAI",
        "value": "CASH",
    },
    {
        "name": "NON TUNAI",
        "value": "NON-CASH",
    },
]

export const paymentProvidersData = [
    {
        "name": "BANK",
        "children": [
            { "name": "Bank BCA", "value": "BCA" },
            { "name": "Bank Mandiri", "value": "MANDIRI" },
            { "name": "Bank BRI", "value": "BRI" },
            { "name": "Bank BNI", "value": "BNI" },
            { "name": "CIMB Niaga", "value": "CIMB" },
            { "name": "Bank Lain", "value": "OTHER" },
        ]
    },
    {
        "name": "E-Wallet",
        "children": [
            { "name": "OVO", "value": "OVO" },
            { "name": "Go-Pay", "value": "GOPAY" },
            { "name": "Dana", "value": "DANA" },
            { "name": "Link Aja", "value": "LINKAJA" },
        ]
    },
    {
        "name": "Lainnya",
        "children": [
            { "name": "Gift Card", "value": "GIFTCARD" },
            { "name": "Go-Food", "value": "GOFOOD" },
            { "name": "Grab-Food", "value": "GRABFOOD" },
            { "name": "Lainnya", "value": "OTHER" },
        ]
    },
];