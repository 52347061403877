import numeral from 'numeral';



export const money = (val) => {
    if (!val) return 0
    return val.toLocaleString('id-ID', { maximumFractionDigits: 2 });
}


export const countTotal = (sale, taxAmount = 0) => {
    let subTotal = 0, discount = 0, totalTax = 0, total = 0
    if (sale.items?.length) {

        for (const element of sale.items) {
            let st = element.qty * element.price
            if (element.discount?.type == 2) {
                discount += (element.discount?.amount ?? 0)
            }
            if (element.discount?.type == 1) {
                discount += element.qty * (element.price * ((element.discount?.percent ?? 0) / 100))
            }
            subTotal += st
        }


        totalTax = (subTotal - discount) * taxAmount
        total = (subTotal - discount) + totalTax

    }
    return {
        subTotal,
        discount,
        totalTax,
        total,
    }
}
