import { PaginationReq } from "../model/pagination"
import { AddMaterialReq, AddProductPackagingReq, AddProductUnitReq, PackagingReq, Price, PriceCategoryReq, PriceReq, Product, ProductAddReq, ProductCategoryAddReq, ProductEditReq, Unit, UnitReq } from "../model/product";
import { customFetch } from "../utils/helper"

export let getProducts = async (pagination: PaginationReq, categoryIds?: string[], storeId?: string, varianStock? :boolean) => {
    var params: Record<string, string> = {
        page: pagination.page.toString(),
        limit: pagination.limit.toString(),
        search: pagination.search ?? "",
        
    };

    if (pagination.isSell) {
        params["is_sell"] = pagination.isSell ? "1" : "0"
    }
    
    if (pagination.isBuy) {
        params["is_buy"] = pagination.isBuy ? "1" : "0"
    }
    if (pagination.isMaterial) {
        params["is_material"] = pagination.isMaterial ? "1" : "0"
    }
    if (pagination.isManufacture) {
        params["is_manufacture"] = pagination.isManufacture ? "1" : "0"
    }
    if (storeId && storeId != "") {
        params["store_id"] = storeId
    }
    if (categoryIds && categoryIds.join(",").length > 0) {
        params["categoryIds"] = categoryIds.join(",")
    }
    if (varianStock) {
        params["varian_stock"] = "1"
    }
    return await customFetch(`Product/?${new URLSearchParams(params)}`)
}
export let getProductDetail = async (uuid: string) => {
    return await customFetch(`Product/${uuid}`)
}

export let addProduct = async (req: ProductAddReq) => {
    return await customFetch(`Product/`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}

export let addVarian = async (uuid: string, data: string) => {
    return await customFetch(`Product/${uuid}/AddVarian`, {
        method: "POST",
        body: JSON.stringify({
            product_id: { Value: true, String: uuid },
            data
        })
    })
}

export let editProduct = async (uuid: string, req: ProductEditReq) => {
    return await customFetch(`Product/${uuid}`, {
        method: "PUT",
        body: JSON.stringify(req)
    })
}

export let addProductCategory = async (req: ProductCategoryAddReq) => {
    return await customFetch(`ProductCategory/`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}
export let deleteProduct = async (uuid: string) => {
    return await customFetch(`Product/${uuid}`, {
        method: "DELETE",
    })
}
export let getProductCategories = async (pagination: PaginationReq, categoryIds?: string[]) => {
    var params: Record<string, string> = {
        page: pagination.page.toString(),
        limit: pagination.limit.toString(),
        search: pagination.search ?? "",
    };

    if (categoryIds && categoryIds.length > 0) {
        params["categoryIds"] = categoryIds.join(",")
    }
 

    return await customFetch(`ProductCategory/?${new URLSearchParams(params)}`)
}
export let getUnits = async (pagination: PaginationReq) => {
    var params: Record<string, string> = {
        page: pagination.page.toString(),
        limit: pagination.limit.toString(),
        search: pagination.search ?? "",
    };

    return await customFetch(`Unit/?${new URLSearchParams(params)}`)
}
export let addUnit = async (req: UnitReq) => {
    return await customFetch(`Unit/`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}
export let deleteUnit = async (uuid: string) => {
    return await customFetch(`Unit/${uuid}`, {
        method: "DELETE",
    })
}


export let addProductUnit = async (uuid: string, req: AddProductUnitReq) => {
    return await customFetch(`Product/${uuid}/AddUnit`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}
export let addMaterial = async (uuid: string, req: AddMaterialReq) => {
    return await customFetch(`Product/${uuid}/AddMaterial`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}
export let deleteProductUnit = async (uuid: string, unitId: string) => {
    return await customFetch(`Product/${uuid}/DeleteUnit/${unitId}`, {
        method: "DELETE",
    })
}


export let getPackagings = async (pagination: PaginationReq) => {
    var params: Record<string, string> = {
        page: pagination.page.toString(),
        limit: pagination.limit.toString(),
        search: pagination.search ?? "",
    };

    return await customFetch(`Packaging/?${new URLSearchParams(params)}`)
}


export let addPackaging = async (req: PackagingReq) => {
    return await customFetch(`Packaging/`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}

export let deletePackaging = async (uuid: string) => {
    return await customFetch(`Packaging/${uuid}`, {
        method: "DELETE",
    })
}

export let addProductPackaging = async (uuid: string, req: AddProductPackagingReq) => {
    return await customFetch(`Product/${uuid}/Packaging`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}

export let deleteProductPackaging = async (uuid: string, unitId: string) => {
    return await customFetch(`Product/${uuid}/Packaging/${unitId}`, {
        method: "DELETE",
    })
}
export let deleteProductPriceCategory = async (uuid: string, unitId: string) => {
    return await customFetch(`Product/${uuid}/DeletePrice/${unitId}`, {
        method: "DELETE",
    })
}


export let getPriceCategories = async (pagination: PaginationReq, categoryIds?: string[]) => {
    var params: Record<string, string> = {
        page: pagination.page.toString(),
        limit: pagination.limit.toString(),
        search: pagination.search ?? "",
    };

    if (categoryIds && categoryIds.length > 0) {
        params["categoryIds"] = categoryIds.join(",")
    }
 

    return await customFetch(`PriceCategory/?${new URLSearchParams(params)}`)
}

export let addPriceCategory = async ( req: PriceCategoryReq) => {
    return await customFetch(`PriceCategory/`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}
export let deletePriceCategory = async ( uuid: string) => {
    return await customFetch(`PriceCategory/${uuid}`, {
        method: "DELETE",
    })
}


export let addProductPriceCategory = async (uuid: string, req: PriceReq) => {
    return await customFetch(`Product/${uuid}/AddPrice`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}

export let deleteProductCategory = async ( uuid: string) => {
    return await customFetch(`ProductCategory/${uuid}`, {
        method: "DELETE",
    })
}

export let getProductStocks = async (uuid: string) => {
    return await customFetch(`Product/${uuid}/Stock`)
}
export let getProductCirculations = async (uuid: string, pagination: PaginationReq) => {
    var params: Record<string, string> = {
        page: pagination.page.toString(),
        limit: pagination.limit.toString(),
        product_id: uuid
    };
    if (pagination.search) {
        params["search"] = pagination.search
    }
    return await customFetch(`Circulation/?${new URLSearchParams(params)}`)
}