import Avatar from "../components/avatar";
import Loading from "../components/loading";
import { CompanySimple } from "../model/company";
import { postLogin, postLoginGoogle } from "../repositories/login";
import { getPermissions, getProfile } from "../repositories/my";
import { setMyCompanies, setProfileStorage, setStorage } from "../utils/helper";
// import Image from "next/image";
// import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";
import { render } from "react-dom";
import Swal from "sweetalert2";
import { CredentialResponse, GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { useNavigate } from "react-router-dom";


export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const [permissions, setPermissions] = useState<string[]>([]);
  const [companies, setCompanies] = useState<CompanySimple[]>([]);
  const [step, setStep] = useState("login");
  const [loading, setLoading] = useState(false);
  const nav = useNavigate()


  useEffect(() => {
  }, []);

  let postLoginReq = async () => {

    // console.log(email, password)
    try {
      setLoading(true)
      var resp = await postLogin({ username: email, password, device: 'web', fcm_token: '' })
      var respJson = await resp.json()
      // setStorage({token: respJson.token, permissions: respJson.permissions})
      setToken(respJson.token)
      setPermissions(respJson.permissions)
      setCompanies(respJson.companies)
      setMyCompanies(respJson.companies)
      setStep("select-company")
    } catch (error) {
      Swal.fire("Perhatian", `${error}`, "error")
    } finally {
      setLoading(false)
    }

  }

  const onSuccessGoogle = async (val: CredentialResponse) => {
    try {
      setLoading(true)
      var resp = await postLoginGoogle({ token: val.credential!, device: 'web', fcm_token: '' })
      var respJson = await resp.json()
      // setStorage({token: respJson.token, permissions: respJson.permissions})
      setToken(respJson.token)
      setPermissions(respJson.permissions)
      setCompanies(respJson.companies)
      setMyCompanies(respJson.companies)
      setStep("select-company")
    } catch (error) {
      Swal.fire("Perhatian", `${error}`, "error")
    } finally {
      setLoading(false)
    }
  }

  let renderLogin = () => (<div className="w-full bg-white rounded-lg shadow  md:mt-0 sm:max-w-md xl:p-0  ">
    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
      <h1 className="text-xl font-medium leading-tight tracking-tight text-gray-900 md:text-2xl ">
        Login dengan email anda
      </h1>
      <div className="space-y-4 md:space-y-6">
        <div>
          <label
            htmlFor="email"
            className="block mb-2 text-sm font-medium text-gray-900 "
          >
            Email
          </label>
          <input
            type="email"
            name="email"
            id="email"
            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
            placeholder="name@company.com"
            required={true}
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </div>
        <div>
          <label
            htmlFor="password"
            className="block mb-2 text-sm font-medium text-gray-900 "
          >
            Password
          </label>
          <input
            type="password"
            name="password"
            id="password"
            placeholder="••••••••"
            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
            required={true}
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
        </div>
        <div className="flex items-center justify-between">
          <div className="flex items-start">
          </div>
          <a
            href="#"
            className="text-sm font-medium text-primary-600 hover:underline "
          >
            Lupa password?
          </a>
        </div>
        <button
          onClick={postLoginReq}
          className="w-full text-white bg-orange-600 hover:bg-orange-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
        >
          Sign in
        </button>
        <div className="flex justify-center">

          <GoogleLogin
            onSuccess={onSuccessGoogle}
            onError={() => {
              console.log('Login Failed');
            }}
          />

        </div>
        {/* <p className="text-sm font-light text-gray-500 ">
        Don’t have an account yet?{" "}
        <a
          href="#"
          className="font-medium text-primary-600 hover:underline "
        >
          Sign up
        </a>
      </p> */}
      </div>
    </div>
  </div>)

  let renderCompany = () => (<div className="w-full bg-white rounded-lg shadow  md:mt-0 sm:max-w-md xl:p-0  ">
    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
      <h1 className="text-xl font-medium leading-tight tracking-tight text-gray-900 md:text-2xl ">
        Pilih Perusahaan
      </h1>
      <ul className=" max-h-80 overflow-y-auto">
        {companies.map(e => (
          <li key={e.uuid} className="flex  items-center  my-4 cursor-pointer" onClick={async () => {
              await setStorage({ token, permissions, company: e })
            try {
              setLoading(true)
              var resp = await getPermissions()
              var respJson = await resp.json()
              setPermissions(respJson.data)
              setStorage({ token, permissions: respJson.data, company: e })
              var profile = await getProfile()
              var profileJson = await profile.json()
              console.log("profileJson", profileJson.data)
              setProfileStorage(profileJson.data)
              window.location.href ="/"

            } catch (error) {
              Swal.fire("Perhatian", `${error}`, "error")
            } finally {
              setLoading(false)
            }
          }}>
            <Avatar size={40} marginRight={10} name={e.name} url={e.logo} />
            <span className=" text-lg font-normal hover:font-medium">
              {e.name}
            </span>
          </li>
        ))}
      </ul>
    </div>
  </div>)

  return (
    <>
      {loading && <Loading />}

      <section className=" ">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <a
            href="#"
            className="flex items-center mb-2 text-2xl font-semibold text-white "
          >
            <img
              className=" w-24 h-24 mb-10"
              src="/images/logo-jaraya-white.png"
              alt="logo"
            />
           
          </a>

          {step == "login" ? renderLogin() : null}
          {step == "select-company" ? renderCompany() : null}
        </div>
      </section>

    </>
  );
}
