import { dateRangeChart, dateRangeProgress } from "../objects/dashboard"
import { customFetch } from "../utils/helper"
import moment from "moment"
import { json } from "stream/consumers"

export let getSaleProgress = async (req: dateRangeProgress) => {
    let params: Record<string, string> = {
        "start_date": moment(req.start_date).format("YYYY-MM-DD 00:00:00"),
        "end_date": moment(req.end_date).format("YYYY-MM-DD 23:59:59"),
        "start_date_before": moment(req.start_date_before).format("YYYY-MM-DD 00:00:00"),
        "end_date_before": moment(req.end_date_before).format("YYYY-MM-DD 23:59:59"),
    }
    if (req.merchant_id) {
        params["merchant_id"] = req.merchant_id
    }
    return await customFetch(`Dashboard/SaleProgress?${new URLSearchParams(params)}`)
}

export let getBuyProgress = async (req: dateRangeProgress) => {
    let params: Record<string, string> = {
        "start_date": moment(req.start_date).format("YYYY-MM-DD 00:00:00"),
        "end_date": moment(req.end_date).format("YYYY-MM-DD 23:59:59"),
        "start_date_before": moment(req.start_date_before).format("YYYY-MM-DD 00:00:00"),
        "end_date_before": moment(req.end_date_before).format("YYYY-MM-DD 23:59:59"),
    }
    if (req.merchant_id) {
        params["merchant_id"] = req.merchant_id
    }
    return await customFetch(`Dashboard/BuyProgress?${new URLSearchParams(params)}`)
}
export let getTransactionProgress = async (req: dateRangeProgress) => {
    let params: Record<string, string> = {
        "start_date": moment(req.start_date).format("YYYY-MM-DD 00:00:00"),
        "end_date": moment(req.end_date).format("YYYY-MM-DD 23:59:59"),
        "start_date_before": moment(req.start_date_before).format("YYYY-MM-DD 00:00:00"),
        "end_date_before": moment(req.end_date_before).format("YYYY-MM-DD 23:59:59"),
    }
    if (req.merchant_id) {
        params["merchant_id"] = req.merchant_id
    }
    return await customFetch(`Dashboard/TransactionProgress?${new URLSearchParams(params)}`)
}
export let getChartData = async (req: dateRangeChart[]) => {
    
    return await customFetch(`Dashboard/Chart`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}

export let getTopProducts = async () => {
    return await customFetch(`Dashboard/Product`)
}
export let getProductActive = async () => {
    return await customFetch(`Dashboard/ProductActive`)
}