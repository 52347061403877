import type { FC } from 'react';

interface NoAccessProps { }

const NoAccess: FC<NoAccessProps> = ({ }) => {
    return (<div className="p-6">
        <div className="mt-6 grid gap-x-8 gap-y-4 sm:gap-y-0 xl:grid-cols-4 lg:grid-cols-4 sm:grid-cols-2 md:grid-cols-2 ">
            <div className="mb-5 min-h-[600px] flex flex-col justify-center  items-center col-span-8 rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer" >
                <img src='/images/no_access.png' className=' w-4/12' />
                <h3 className=' font-medium text-2xl px-36 mt-4 text-center'>Mohon maaf! anda tidak mempunyai akses ke halaman ini, silakan hubungi admin anda</h3>
            </div>
        </div>
    </div>);
}
export default NoAccess;