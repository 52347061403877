import { CirculationReq } from "../model/circulation";
import { PaginationReq } from "../model/pagination";
import { customFetch } from "../utils/helper";
import moment from "moment";

export let getCirculations = async (pagination: PaginationReq, customParams?: { returnId?: string }) => {
    var params: Record<string, string> = {
        page: pagination.page.toString(),
        limit: pagination.limit.toString(),

    };
    if (pagination.search) {
        params["search"] = pagination.search
    }
    if (pagination.dateRange != null) {
        params["start_date"] = moment(pagination.dateRange[0]).format("YYYY-MM-DD")
        params["end_date"] = moment(pagination.dateRange[1]).format("YYYY-MM-DD")
    }
    if (pagination.product_id) {
        params["product_id"] = pagination.product_id
    }
    if (pagination.store_id) {
        params["store_id"] = pagination.store_id
    }
    if (customParams?.returnId) {
        params["return_id"] = customParams?.returnId ?? ""
    }
    return await customFetch(`Circulation/?${new URLSearchParams(params)}`)
}
export let deleteCirculation = async (uuid: string) => {
    return await customFetch(`Circulation/${uuid}`, {
        method: "DELETE"
    })
}
export let addCirculation = async (req: CirculationReq) => {
    return await customFetch(`Circulation/`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}
export let getCirculationStock = async (product_id : string, store_id: string) => {
    return await customFetch(`Circulation/Stock?product_id=${product_id}&store_id=${store_id}`)
}
