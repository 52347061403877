import { useState, type FC, useContext, useEffect } from 'react';
import ModalCustom from './modal_custom';
import { FolderIcon } from '@heroicons/react/24/outline';
import { LoadingContext } from '../../objects/loading_context';
import Swal from 'sweetalert2';
import { ContactReq } from '../../model/company';
import { setNullString } from '../../utils/helper';
import { addCustomer, updateCustomer } from '../../repositories/customer';
import { addVendor, updateVendor } from '../../repositories/vendor';
import { Customer } from '../../model/customer';
import { Vendor } from '../../model/vendor';
import { addEmployee, updateEmployee } from '../../repositories/employee';
import InlineForm from '../inline_form';
import { Employee } from '../../model/employee';

interface FormContactProps {
    data?: Customer | Vendor | Employee | null
    contactType: string
    isOpen: boolean
    setIsOpen: (a: boolean) => void
    onClose: () => void
}

const FormContact: FC<FormContactProps> = ({
    contactType,
    isOpen,
    setIsOpen,
    onClose,
    data
}) => {
    let { isLoading, setIsLoading } = useContext(LoadingContext);

    const [name, setName] = useState("")
    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [employeeIdentityNumber, setEmployeeIdentityNumber] = useState("");
    const [grade, setGrade] = useState("");
    const [logo, setLogo] = useState("")
    const [cover, setCover] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [fax, setFax] = useState("")
    const [address, setAddress] = useState("")
    const [contactPerson, setContactPerson] = useState("")
    const [contactPersonPosition, setContactPersonPosition] = useState("")

    useEffect(() => {
        if (data) {

            setName(data?.name ?? "")
            setLogo(data?.logo ?? "")
            setEmail(data?.email ?? "")
            setPhone(data?.phone ?? "")
            setFax(data?.fax ?? "")
            setAddress(data?.address ?? "")
            setContactPerson(data?.contact_person ?? "")
            setContactPersonPosition(data?.contact_person_position ?? "")

            if (contactType == "employee") {
                setGrade(data?.grade ?? "")
                setEmployeeIdentityNumber(data?.employee_identity_number ?? "")
                setFirstName(data?.first_name ?? "")
                setMiddleName(data?.middle_name ?? "")
                setLastName(data?.last_name ?? "")
            }

        }


    }, [data]);

    const contactSave = async () => {
        try {
            setIsLoading(true)
            switch (contactType) {
                case "customer":
                    await addCustomer({
                        name: setNullString(name),
                        email: setNullString(email),
                        phone: setNullString(phone),
                        fax: setNullString(fax),
                        address: setNullString(address),
                        contact_person: setNullString(contactPerson),
                        contact_person_position: setNullString(contactPersonPosition),
                    })
                    break;
                case "vendor":
                    await addVendor({
                        name,
                        email: setNullString(email),
                        phone: setNullString(phone),
                        fax: setNullString(fax),
                        address: setNullString(address),
                        contact_person: setNullString(contactPerson),
                        contact_person_position: setNullString(contactPersonPosition),
                    })
                    break;
                case "employee":
                    await addEmployee({
                        first_name: firstName,
                        middle_name: middleName,
                        last_name: lastName,
                        email: (email),
                        phone: (phone),
                        fax: (fax),
                        address: (address),
                        contact_person: (contactPerson),
                        contact_person_position: (contactPersonPosition),
                        employee_identity_number: employeeIdentityNumber,
                        grade: grade
                    })
                    break;
                default:
                    break;
            }

            clearForm()
            if (onClose) onClose()

        } catch (error) {
            Swal.fire("Perhatian", `${error}`, "error")
        } finally {
            setIsLoading(false)
        }
    }

    const setTitle = (): string => {
        switch (contactType) {
            case "customer":
                return "Form Konsumen"
            case "vendor":
                return "Form Supplier / Vendor"
            case "employee":
                return "Form Pegawai"

            default:
                break;
        }
        return ""
    }

    const contactUpdate = async () => {
        try {
            setIsLoading(true)
            console.log(contactType)
            switch (contactType) {
                case "customer":
                    await updateCustomer(data?.uuid ?? "", {
                        name: setNullString(name),
                        email: setNullString(email),
                        phone: setNullString(phone),
                        fax: setNullString(fax),
                        address: setNullString(address),
                        contact_person: setNullString(contactPerson),
                        contact_person_position: setNullString(contactPersonPosition),
                    })
                    break;
                case "vendor":
                    await updateVendor(data?.uuid ?? "", {
                        name,
                        email: setNullString(email),
                        phone: setNullString(phone),
                        fax: setNullString(fax),
                        address: setNullString(address),
                        contact_person: setNullString(contactPerson),
                        contact_person_position: setNullString(contactPersonPosition),
                    })
                    break;
                case "employee":
                    await updateEmployee(data?.uuid ?? "", {
                        first_name: firstName,
                        middle_name: middleName,
                        last_name: lastName,
                        email: (email),
                        phone: (phone),
                        fax: (fax),
                        address: (address),
                        contact_person: (contactPerson),
                        contact_person_position: (contactPersonPosition),
                        employee_identity_number: employeeIdentityNumber,
                        grade: grade
                    })
                    break;
                default:
                    break;
            }

            clearForm()
            if (onClose) onClose()

        } catch (error) {
            Swal.fire("Perhatian", `${error}`, "error")
        } finally {
            setIsLoading(false)
        }
    }
    const clearForm = () => {
        setName("")
        setLogo("")
        setCover("")
        setEmail("")
        setPhone("")
        setFax("")
        setAddress("")
        setContactPerson("")
        setContactPersonPosition("")
    }

    return (<ModalCustom height={800} title={setTitle()} isOpen={isOpen} setIsOpen={setIsOpen} onClose={() => {
        clearForm()
        onClose()
    }}
        footer={<div className="mt-4">
            <button
                type="button"
                className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                onClick={() => {
                    if (data != null) {
                        contactUpdate()
                    } else {
                        contactSave()
                    }
                }}
            >
                <FolderIcon
                    className=" h-5 w-5 mr-2"
                    aria-hidden="true" />  Simpan
            </button>
        </div>}
    >
        <div className='mt-4'>
            {contactType == "employee" ? <>
                <InlineForm title={'Nama Depan'}>
                    <input
                        className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                        type="text"
                        placeholder={"Nama Depan"}
                        value={firstName}
                        onChange={(el) => setFirstName(el.target.value)}
                    />
                </InlineForm>
                <InlineForm title={'Nama Tengah'}>
                    <input
                        className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                        type="text"
                        placeholder={"Nama Tengah"}
                        value={middleName}
                        onChange={(el) => setMiddleName(el.target.value)}
                    />
                </InlineForm>
                <InlineForm title={'Nama Belakang'}>
                    <input
                        className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                        type="text"
                        placeholder={"Nama Belakang"}
                        value={lastName}
                        onChange={(el) => setLastName(el.target.value)}
                    />
                </InlineForm>
            </> :
                <div className="flex items-center mb-6">
                    <div className="w-1/3">
                        <label className="block text-gray-900 mb-0 pr-4">Nama Perusahaan</label>
                    </div>
                    <div className="w-2/3">
                        <input
                            className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                            type="text"
                            placeholder={"Nama Perusahaan"}
                            value={name}
                            onChange={(el) => setName(el.target.value)}
                        />
                    </div>
                </div>
            }
            <div className="flex items-center mb-6">
                <div className="w-1/3">
                    <label className="block text-gray-900 mb-0 pr-4">Email</label>
                </div>
                <div className="w-2/3">
                    <input
                        className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                        type="text"
                        placeholder={"Email"}
                        value={email}
                        onChange={(el) => setEmail(el.target.value)}
                    />
                </div>
            </div>
            <div className="flex items-center mb-6">
                <div className="w-1/3">
                    <label className="block text-gray-900 mb-0 pr-4">Telepon</label>
                </div>
                <div className="w-2/3">
                    <input
                        className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                        type="text"
                        placeholder={"Telepon"}
                        value={phone}
                        onChange={(el) => setPhone(el.target.value)}
                    />
                </div>
            </div>
            <div className="flex items-center mb-6">
                <div className="w-1/3">
                    <label className="block text-gray-900 mb-0 pr-4">Fax</label>
                </div>
                <div className="w-2/3">
                    <input
                        className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                        type="text"
                        placeholder={"Fax"}
                        value={fax}
                        onChange={(el) => setFax(el.target.value)}
                    />
                </div>
            </div>
            <div className="flex  mb-6">
                <div className="w-1/3">
                    <label className="block text-gray-900 mb-0 pr-4">Alamat</label>
                </div>
                <div className="w-2/3">
                    <textarea
                        className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                        rows={9}
                        placeholder={"Alamat"}
                        value={address}
                        onChange={(el) => setAddress(el.target.value)}
                    />
                </div>
            </div>
            {contactType == "employee" ? <>
            <InlineForm title={'NIP/NIK'}>
                    <input
                        className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                        type="text"
                        placeholder={"NIP/NIK"}
                        value={employeeIdentityNumber}
                        onChange={(el) => setEmployeeIdentityNumber(el.target.value)}
                    />
                </InlineForm>
                <InlineForm title={'Pangkat'}>
                    <input
                        className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                        type="text"
                        placeholder={"Pangkat"}
                        value={grade}
                        onChange={(el) => setGrade(el.target.value)}
                    />
                </InlineForm>
                
            </> :
                <div className="flex items-center mb-6">
                    <div className="w-1/3">
                        <label className="block text-gray-900 mb-0 pr-4">Penanggung Jawab</label>
                    </div>
                    <div className="w-2/3">
                        <input
                            className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                            type="text"
                            placeholder={"Penanggung Jawab"}
                            value={contactPerson}
                            onChange={(el) => setContactPerson(el.target.value)}
                        />
                    </div>
                </div>
            }
            <div className="flex items-center mb-6">
                <div className="w-1/3">
                    <label className="block text-gray-900 mb-0 pr-4">Jabatan</label>
                </div>
                <div className="w-2/3">
                    <input
                        className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                        type="text"
                        placeholder={"Jabatan"}
                        value={contactPersonPosition}
                        onChange={(el) => setContactPersonPosition(el.target.value)}
                    />
                </div>
            </div>
        </div>

    </ModalCustom>);
}
export default FormContact;