import { MerchantSale } from "../model/pos";
import { SAVED_SALES, SELECTED_MERCHANT_ID } from "../objects/constants";
import { asyncLocalStorage } from "./helper";

export const saveSale = async (sale: MerchantSale) : Promise<MerchantSale[]> => {
    let merchantID = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID)
    sale.merchant_id = merchantID
    let saved = await getSales()
    saved.push(sale)
    asyncLocalStorage.setItem(SAVED_SALES, JSON.stringify(saved))
    return saved
}

export const getSales = async (): Promise<MerchantSale[]> => {
    let merchantID = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID)
    let saved = await asyncLocalStorage.getItem(SAVED_SALES)
    if (saved) {
        let parsed = JSON.parse(saved) as MerchantSale[]
        return parsed.filter(e => e.merchant_id == merchantID)
    }
    return []
}
export const delSale = async (sale: MerchantSale): Promise<MerchantSale[]> => {
    let saved = await asyncLocalStorage.getItem(SAVED_SALES)
    if (saved) {
        let parsed = JSON.parse(saved) as MerchantSale[]
        let deleted = parsed.filter(e => e.code != sale.code)
        asyncLocalStorage.setItem(SAVED_SALES, JSON.stringify(deleted))
        return deleted
    }
    return []
}