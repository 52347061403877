import { Machine, Merchant, PaymentProvider, PaymentProviderReq } from '../../model/merchant';
import { LoadingContext } from '../../objects/loading_context';
import { addMachine, addMerchantAccount, addMerchantDiscount, addMerchantPriceCategory, addMerchantProduct, addMerchantProductCategory, addMerchantStore, addMerchantTax, addMerchantUser, deleteMachine, deleteMerchantAccount, deleteMerchantDiscount, deleteMerchantPriceCategory, deleteMerchantProduct, deleteMerchantProductCategory, deleteMerchantStore, deleteMerchantTax, deleteMerchantUser, getMerchantAccounts, getMerchantDetail, getMerchantDiscounts, getMerchantMachines, getMerchantPriceCategories, getMerchantProductCategories, getMerchantProducts, getMerchantStores, getMerchantTaxes, getMerchantUsers, pinnedMerchantProduct, updateMachine, updateMerchant } from '../../repositories/merchant';
import { useContext, useEffect, useState, type FC } from 'react';
import { Panel, Stack } from 'rsuite';
import Swal from 'sweetalert2';
import InlineForm from '../inline_form';
import { CheckCircleIcon, CheckIcon, FolderIcon, PlusIcon, TrashIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { setNullString } from '../../utils/helper';
import { Switch } from '@headlessui/react';
import { Tax } from '../../model/tax';
import { getTaxes } from '../../repositories/tax';
import { Store } from '../../model/store';
import { getStores } from '../../repositories/store';
import { getPriceCategories, getProductCategories, getProducts } from '../../repositories/product';
import { PriceCategory, Product, ProductCategory, Unit } from '../../model/product';
import { getAccounts } from '../../repositories/account';
import { Account } from '../../model/account';
import CustomTable, { TableRecord } from '../custom_table';
import { ACCOUNT_LABELS } from '../../objects/constants';
import ModalAccount from '../modals/modal_account';
import { money } from '../../utils/number';
import ModalCustom from '../modals/modal_custom';
import CurrencyInput from 'react-currency-input-field';
import Select, { SingleValue } from 'react-select';
import { SelectOption, colourStyles } from '../../utils/style';
import ModalSearch from '../modals/modal_search';
import Avatar from '../avatar';
import { CompanyMember } from '../../model/company';
import { getCompanyMembers } from '../../repositories/company';
import { Discount } from '../../model/discount';
import { getDiscounts } from '../../repositories/discount';
import Moment from 'react-moment';
import { useNavigate } from 'react-router-dom';


interface CashierProps {

}

const CashierSetting: FC<CashierProps> = ({

}) => {
  const [mounted, setMounted] = useState(false);
  const [merchant, setMerchant] = useState<Merchant | null>(null);
  let { isLoading, setIsLoading } = useContext(LoadingContext);
  const [name, setName] = useState("")
  const [logo, setLogo] = useState("")
  const [cover, setCover] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [fax, setFax] = useState("")
  const [address, setAddress] = useState("")
  const [contactPerson, setContactPerson] = useState("")
  const [contactPersonPosition, setContactPersonPosition] = useState("")
  const [priceTaxInclude, setPriceTaxInclude] = useState(false);
  const [message, setMessage] = useState("");
  const [messageToQr, setMessageToQr] = useState("");
  const [taxes, setTaxes] = useState<Tax[]>([]);
  const [taxId, setTaxId] = useState("");
  const [stores, setStores] = useState<Store[]>([]);
  const [defaultStoreId, setDefaultStoreId] = useState("");
  const [defaultPriceCategoryId, setDefaultPriceCategoryId] = useState("");
  const [allPriceCategories, setAllPriceCategories] = useState<PriceCategory[]>([]);
  const [checkStock, setCheckStock] = useState(false);
  const [assetAccounts, setAssetAccounts] = useState<Account[]>([]);
  const [merchantAccounts, setMerchantAccounts] = useState<Account[]>([]);
  const [providers, setProviders] = useState<PaymentProvider[]>([]);
  const [modalAccountOpen, setModalAccountOpen] = useState(false);
  const [machines, setMachines] = useState<Machine[]>([]);
  const [modalMachineOpen, setModalMachineOpen] = useState(false);
  const [inputMachineName, setInputMachineName] = useState("");
  const [inputDeposit, setInputDeposit] = useState(0);
  const [selectedMachine, setSelectedMachine] = useState<Machine | null>(null);
  const [productCategories, setProductCategories] = useState<ProductCategory[]>([]);
  const [allProductCategories, setAllProductCategories] = useState<ProductCategory[]>([]);
  const [modalProductCategoryOpen, setModalProductCategoryOpen] = useState(false);
  const [priceCategories, setPriceCategories] = useState<PriceCategory[]>([]);
  const [modalPriceCategoryOpen, setModalPriceCategoryOpen] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [modalProductOpen, setModalProductOpen] = useState(false);
  const [searchProduct, setSearchProduct] = useState("");
  const [allProducts, setAllProducts] = useState<Product[]>([]);
  const [productSelect, setProductSelect] = useState<SelectOption | null>();
  const [unitSelect, setUnitSelect] = useState<SelectOption | null>();
  const [units, setUnits] = useState<Unit[]>([]);
  const [modalServiceOpen, setModalServiceOpen] = useState(false);
  const [isProductPinned, setIsProductPinned] = useState(false);
  const nav = useNavigate()
  const [modalStoreOpen, setModalStoreOpen] = useState(false);
  const [merchantStores, setMerchantStores] = useState<Store[]>([]);
  const [searchStore, setSearchStore] = useState("");
  const [allStores, setAllStores] = useState<Store[]>([]);
  const [merchantUsers, setMerchantUsers] = useState<CompanyMember[]>([]);
  const [modalMerchantUserOpen, setModalMerchantUserOpen] = useState(false);
  const [allMembers, setAllMembers] = useState<CompanyMember[]>([]);
  const [merchantTaxes, setMerchantTaxes] = useState<Tax[]>([]);
  const [modalTaxOpen, setModalTaxOpen] = useState(false);
  const [modalDiscountOpen, setModalDiscountOpen] = useState(false);
  const [merchantDiscounts, setMerchantDiscounts] = useState<Discount[]>([]);
  const [allDiscounts, setAllDiscounts] = useState<Discount[]>([]);


  useEffect(() => {
    setMounted(true)
  }, []);

  useEffect(() => {
    getDetail()
    getTaxes({ page: 1, limit: 1000, search: "", type: "Liability" })
      .then(v => v.json())
      .then(v => setTaxes(v.data))
    getDiscounts({ page: 1, limit: 1000, search: "" })
      .then(v => v.json())
      .then(v => setAllDiscounts(v.data))
    getStores({ page: 1, limit: 1000, search: "", })
      .then(v => v.json())
      .then(v => {
        setStores(v.data)
        setAllStores(v.data)
      })
    getPriceCategories({ page: 1, limit: 1000, search: "", })
      .then(v => v.json())
      .then(v => setAllPriceCategories(v.data))
    getAccounts({ page: 1, limit: 10, type: "Asset", cashflowGroup: "current_asset" })
      .then(v => v.json())
      .then(v => {
        setAssetAccounts(v.data)
      })
    getMerchantAccounts()
      .then(v => v.json())
      .then(v => {
        setMerchantAccounts(v.data)
      })
    getMerchantMachines()
      .then(v => v.json())
      .then(v => {
        setMachines(v.data)
      })
    getMerchantProductCategories()
      .then(v => v.json())
      .then(v => {
        setProductCategories(v.data)
      })
    getProductCategories({ page: 1, limit: 1000 })
      .then(v => v.json())
      .then(v => {
        setAllProductCategories(v.data)
      })
    getMerchantPriceCategories()
      .then(v => v.json())
      .then(v => {
        setPriceCategories(v.data)
      })
    getMerchantProducts()
      .then(v => v.json())
      .then(v => {
        setProducts(v.data)
      })

    getProducts({ page: 1, limit: 10, search: searchProduct, isSell: true })
      .then(v => v.json())
      .then(v => {
        setAllProducts(v.data)
      })

    getMerchantStores()
      .then(v => v.json())
      .then(v => {
        setMerchantStores(v.data)
      })
    getMerchantUsers()
      .then(v => v.json())
      .then(v => {
        setMerchantUsers(v.data)
      })

    getCompanyMembers()
      .then(v => v.json())
      .then(v => {
        setAllMembers(v.data)
      })

    getMerchantTaxes()
      .then(v => v.json())
      .then(v => {
        setMerchantTaxes(v.data)
      })

    getMerchantDiscounts()
      .then(v => v.json())
      .then(v => {
        setMerchantDiscounts(v.data)
      })


  }, [mounted]);

  useEffect(() => {
    if (!merchant) return
    setName(merchant!.name)
    setLogo(merchant!.logo)
    setCover(merchant!.cover)
    setEmail(merchant!.email)
    setPhone(merchant!.phone)
    setFax(merchant!.fax)
    setAddress(merchant!.address)
    setContactPerson(merchant!.contact_person)
    setContactPersonPosition(merchant!.contact_person_position)
    setTaxId(merchant!.tax_id)
    setPriceTaxInclude(merchant!.price_tax_include)
    setMessage(merchant!.message)
    setMessageToQr(merchant!.message_to_qr)
    setDefaultStoreId(merchant!.default_store_id)
    setDefaultPriceCategoryId(merchant!.default_price_category_id)
    setCheckStock(merchant!.check_stock)
    setProviders(merchant!.payment_providers)


  }, [merchant]);

  const getDetail = () => {
    setIsLoading(true)
    getMerchantDetail()
      .then(v => v.json())
      .then(v => setMerchant(v.data))
      .catch(error => Swal.fire("Perhatian", `${error}`, "error"))
      .finally(() => setIsLoading(false))
  }

  const merchantUdate = async () => {
    try {
      setIsLoading(true)
      var resp = await updateMerchant({
        name: name,
        default_store_id: setNullString(defaultStoreId),
        tax_id: setNullString(taxId),
        tax_price_include: priceTaxInclude,
        message: message,
        message_to_qr: messageToQr,
        check_stock: checkStock,
        price_tax_include: priceTaxInclude,
        email: setNullString(email),
        phone: setNullString(phone),
        fax: setNullString(fax),
        address: setNullString(address),
        contact_person: setNullString(contactPerson),
        contact_person_position: setNullString(contactPersonPosition),
        default_price_category_id: setNullString(defaultPriceCategoryId),
        payment_providers: merchant!.payment_providers.map(e => {
          return {
            uuid: setNullString(e.uuid),
            provider: e.provider,
            account_destination_id: setNullString(e.account_destination_id)
          }
        }),

      })
      var respJson = await resp.json()
      getDetail()
    } catch (error) {
      Swal.fire("Perhatian", `${error}`, "error")
    } finally {
      setIsLoading(false)
    }
  }

  const info = () => (<div className='grid gap-x-8 gap-y-4 sm:gap-y-0 xl:grid-cols-4 lg:grid-cols-4 sm:grid-cols-2 md:grid-cols-2'>
    <div className='col-span-2'>
      <Panel header="Info Merchant" bordered className='mb-8'>
        <InlineForm title={"Nama"} >
          <input
            className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
            type="text"
            placeholder={"Nama Perusahaan"}
            value={name}
            onChange={(el) => setName(el.target.value)}
          />
        </InlineForm>
        <InlineForm title={"Email"} >
          <input
            className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
            type="text"
            placeholder={"Email"}
            value={email}
            onChange={(el) => setEmail(el.target.value)}
          />
        </InlineForm>
        <InlineForm title={"Telp"} >
          <input
            className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
            type="text"
            placeholder={"Telp"}
            value={phone}
            onChange={(el) => setPhone(el.target.value)}
          />
        </InlineForm>
        <InlineForm title={"Fax"} >
          <input
            className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
            type="text"
            placeholder={"Fax"}
            value={fax}
            onChange={(el) => setFax(el.target.value)}
          />
        </InlineForm>
        <InlineForm title={"Alamat"} style={{ alignItems: 'start' }}>
          <textarea
            rows={5}
            className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
            placeholder={"Alamat"}
            value={address}
            onChange={(el) => setAddress(el.target.value)}
          />
        </InlineForm>
        <InlineForm title={"Penanggung Jawab"} >
          <input
            className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
            type="text"
            placeholder={"Penanggung Jawab"}
            value={contactPerson}
            onChange={(el) => setContactPerson(el.target.value)}
          />
        </InlineForm>
        <InlineForm title={"Jabatan"} >
          <input
            className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
            type="text"
            placeholder={"Jabatan"}
            value={contactPersonPosition}
            onChange={(el) => setContactPersonPosition(el.target.value)}
          />
        </InlineForm>
        <InlineForm title={"Pajak"} >
          <select
            className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
            value={taxId}
            onChange={(el) => setTaxId(el.target.value)}
          >
            <option value="">Pilih Pajak</option>
            {taxes.map(e => <option key={e.uuid} value={e.uuid}>{e.name}</option>)}
          </select>
        </InlineForm>

        <InlineForm title={"Harga Termasuk Pajak"} >
          <Switch
            checked={priceTaxInclude}
            onChange={setPriceTaxInclude}
            className={`${priceTaxInclude ? 'bg-blue-600' : 'bg-gray-200'
              } relative inline-flex h-6 w-11 items-center rounded-full`}
          >
            <span className="sr-only">"Harga Termasuk Pajak</span>
            <span
              aria-hidden="true"
              className={`${priceTaxInclude ? 'translate-x-6' : 'translate-x-1'
                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
            />
          </Switch>
        </InlineForm>
        <InlineForm title={"Check Stok"} >
          <Switch
            checked={checkStock}
            onChange={setCheckStock}
            className={`${checkStock ? 'bg-blue-600' : 'bg-gray-200'
              } relative inline-flex h-6 w-11 items-center rounded-full`}
          >
            <span className="sr-only">"Check Stok</span>
            <span
              aria-hidden="true"
              className={`${checkStock ? 'translate-x-6' : 'translate-x-1'
                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
            />
          </Switch>
        </InlineForm>
        <InlineForm title={"Gudang"} >
          <select
            className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
            value={defaultStoreId}
            onChange={(el) => setDefaultStoreId(el.target.value)}
          >
            <option value="">Pilih Gudang</option>
            {stores.map(e => <option key={e.uuid} value={e.uuid}>{e.name}</option>)}
          </select>
        </InlineForm>
        <InlineForm title={"Kategori Harga"} >
          <select
            className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
            value={defaultPriceCategoryId}
            onChange={(el) => setDefaultPriceCategoryId(el.target.value)}
          >
            <option value="">Pilih Kategori Harga</option>
            {allPriceCategories.map(e => <option key={e.uuid} value={e.uuid}>{e.name}</option>)}
          </select>
        </InlineForm>
        <InlineForm title={"Footer Message"} style={{ alignItems: 'start' }}>
          <textarea
            rows={5}
            className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
            placeholder={"Footer Message"}
            value={message}
            onChange={(el) => setMessage(el.target.value)}
          />
        </InlineForm>

        <InlineForm title={"Qr Message"} style={{ alignItems: 'start' }}>
          <textarea
            rows={5}
            className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
            placeholder={"Qr Message"}
            value={messageToQr}
            onChange={(el) => setMessageToQr(el.target.value)}
          />
        </InlineForm>
        <div className="mt-4">
          <button
            type="button"
            className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
            onClick={() => merchantUdate()}
          >
            <FolderIcon
              className=" h-5 w-5 mr-2"
              aria-hidden="true" />  Simpan
          </button>
        </div>
      </Panel>
    </div>
    <div className='col-span-2'>
      <Panel header="Pembayaran" bordered className='mb-8'>
        {(merchant?.payment_providers ?? []).map((e) => <InlineForm key={e.provider} title={e.provider} >
          <select
            className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
            value={(merchant?.payment_providers ?? []).find(p => p.provider == e.provider)?.account_destination_id}
            onChange={(el) => {
              let providers = [...merchant!.payment_providers]
              providers = providers.map(p => {
                if (p.provider == e.provider) {
                  p.account_destination_id = el.target.value
                }
                return p;
              })
              setMerchant({
                ...merchant!,
                payment_providers: [...providers]
              })
            }}
          >
            <option value="">Pilih Akun</option>
            {assetAccounts.map(e => <option key={e.uuid} value={e.uuid}>{e.name}</option>)}
          </select>
        </InlineForm>)}


        <div className="mt-4">
          <button
            type="button"
            className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
            onClick={() => merchantUdate()}
          >
            <FolderIcon
              className=" h-5 w-5 mr-2"
              aria-hidden="true" />  Simpan
          </button>
        </div>
      </Panel>
    </div>
  </div>)

  const accountMachine = () => (<div className='grid gap-x-8 gap-y-4 sm:gap-y-0 xl:grid-cols-4 lg:grid-cols-4 sm:grid-cols-2 md:grid-cols-2'>
    <div className='col-span-2'>
      <Panel
        header={
          <Stack justifyContent="space-between">
            <span>Akun</span>
            <button
              type="button"
              className="mr-2 inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
              onClick={() => setModalAccountOpen(true)}
            >
              <PlusIcon
                className=" h-3 w-3 mr-1"
                aria-hidden="true" />  Tambah Akun
            </button>
          </Stack>
        }
        bordered className='mb-8' >
        <CustomTable headers={["#", "Nama", "Tipe", "Kategori/Grup", ""]} headerClasses={[]} datasets={merchantAccounts.map(e => {
          let data: TableRecord = {
            cells: [
              { data: (merchantAccounts.indexOf(e) + 1) },
              { data: <div>{e.name}</div>, },
              { data: ACCOUNT_LABELS[e.type], },
              {
                data: <div>

                  <dl className='text-xs'>
                    <dt >Kategori</dt>
                    <dd>{e.category}</dd>
                    <dt >Grup Arus Kas</dt>
                    <dd>{e.cashflow_group_label}</dd>
                    <dt >Subgrup Arus Kas</dt>
                    <dd>{e.cashflow_subgroup_label}</dd>
                  </dl>
                </div>,
              },
              {
                data: <TrashIcon className="w-5 text-red-600" onClick={() => {
                  Swal.fire({
                    title: "Anda Yakin",
                    text: "Anda tidak akan dapat mengembalikan proses ini!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Ya, Hapus!",
                    cancelButtonText: "Batal"
                  }).then((result) => {
                    if (result.isConfirmed) {
                      setIsLoading(true)
                      deleteMerchantAccount(e.uuid).then(() => {
                        getMerchantAccounts()
                          .then(v => v.json())
                          .then(v => {
                            setMerchantAccounts(v.data)
                          })

                      }).finally(() => setIsLoading(false))
                    }
                  });
                }} />, className: 'w-4 text-right'
              },
            ],
            className: "last:border-0 hover:bg-gray-50"
          }
          return data
        })} />
      </Panel>
    </div>
    <div className='col-span-2'>
      <Panel
        header={
          <Stack justifyContent="space-between">
            <span>Mesin Kasir</span>
            <button
              type="button"
              className="mr-2 inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
              onClick={() => setModalMachineOpen(true)}
            >
              <PlusIcon
                className=" h-3 w-3 mr-1"
                aria-hidden="true" />  Tambah Mesin
            </button>
          </Stack>
        }
        bordered className='mb-8' >
        <CustomTable headers={["#", "Nama", "Deposit", ""]} headerClasses={[]} datasets={machines.map(e => {
          let data: TableRecord = {
            cells: [
              { data: (machines.indexOf(e) + 1) },
              {
                data: <div onClick={() => {
                  setSelectedMachine(e)
                  setInputMachineName(e.name)
                  setInputDeposit(e.deposit)
                  setTimeout(() => {
                    setModalMachineOpen(true)
                  }, 300);
                }} className=' hover:font-medium'>
                  {e.name}
                </div>,
              },
              { data: money(e.deposit), },

              {
                data: <TrashIcon className="w-5 text-red-600" onClick={() => {
                  Swal.fire({
                    title: "Anda Yakin",
                    text: "Anda tidak akan dapat mengembalikan proses ini!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Ya, Hapus!",
                    cancelButtonText: "Batal"
                  }).then((result) => {
                    if (result.isConfirmed) {
                      setIsLoading(true)
                      deleteMachine(e.uuid).then(v => getMerchantMachines()
                        .then(v => v.json())
                        .then(v => {
                          setMachines(v.data)

                        }))
                        .finally(() => setIsLoading(false))

                    }
                  });
                }} />, className: 'w-4 text-right'
              },
            ],
            className: "last:border-0 hover:bg-gray-50"
          }
          return data
        })} />
      </Panel>
    </div>
  </div>)

  const categories = () => (<div className='grid gap-x-8 gap-y-4 sm:gap-y-0 xl:grid-cols-4 lg:grid-cols-4 sm:grid-cols-2 md:grid-cols-2'>
    <div className='col-span-2'>
      <Panel header={
        <Stack justifyContent="space-between">
          <span>Kategori Produk</span>
          <button
            type="button"
            className="mr-2 inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
            onClick={() => setModalProductCategoryOpen(true)}
          >
            <PlusIcon
              className=" h-3 w-3 mr-1"
              aria-hidden="true" />  Tambah Kategori
          </button>
        </Stack>
      } bordered className='mb-8'>
        <CustomTable headers={["Nama", "Kode", "Keterangan", ""]} headerClasses={[]} datasets={productCategories.map((e) => {
          return {
            cells: [
              { data: e.name, },
              { data: e.code, },
              { data: e.description, },
              {
                data: <TrashIcon className="w-5 text-red-600" onClick={() => {
                  Swal.fire({
                    title: "Anda Yakin",
                    text: "Anda tidak akan dapat mengembalikan proses ini!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Ya, Hapus!",
                    cancelButtonText: "Batal"
                  }).then((result) => {
                    if (result.isConfirmed) {
                      setIsLoading(true)
                      deleteMerchantProductCategory(e.uuid).then(v => getMerchantProductCategories()
                        .then(v => v.json())
                        .then(v => {
                          setProductCategories(v.data)
                        }))
                        .finally(() => setIsLoading(false))

                    }
                  });
                }} />, className: 'w-4 text-right'
              },
            ],
            className: "last:border-0 hover:bg-gray-50"
          }
        })} />
      </Panel>
    </div>
    <div className='col-span-2'>
      <Panel header={
        <Stack justifyContent="space-between">
          <span>Kategori Harga</span>
          <button
            type="button"
            className="mr-2 inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
            onClick={() => setModalPriceCategoryOpen(true)}
          >
            <PlusIcon
              className=" h-3 w-3 mr-1"
              aria-hidden="true" />  Tambah Kategori
          </button>
        </Stack>
      } bordered className='mb-8'>
        <CustomTable headers={["Nama", "Kode", "Keterangan", ""]} headerClasses={[]} datasets={priceCategories.map((e) => {
          return {
            cells: [
              { data: e.name, },
              { data: e.code, },
              { data: e.description, },
              {
                data: <TrashIcon className="w-5 text-red-600" onClick={() => {
                  Swal.fire({
                    title: "Anda Yakin",
                    text: "Anda tidak akan dapat mengembalikan proses ini!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Ya, Hapus!",
                    cancelButtonText: "Batal"
                  }).then((result) => {
                    if (result.isConfirmed) {
                      setIsLoading(true)
                      deleteMerchantPriceCategory(e.uuid).then(v => getMerchantPriceCategories()
                        .then(v => v.json())
                        .then(v => {
                          setPriceCategories(v.data)
                        }))
                        .finally(() => setIsLoading(false))

                    }
                  });
                }} />, className: 'w-4 text-right'
              },
            ],
            className: "last:border-0 hover:bg-gray-50"
          }
        })} />
      </Panel>
    </div>
  </div>

  )

  const productService = () => (<div className='grid gap-x-8 gap-y-4 sm:gap-y-0 xl:grid-cols-4 lg:grid-cols-4 sm:grid-cols-2 md:grid-cols-2'>
    <div className='col-span-4'>
      <Panel
        header={
          <Stack justifyContent="space-between">
            <span>Produk</span>
            <button
              type="button"
              className="mr-2 inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
              onClick={() => setModalProductOpen(true)}
            >
              <PlusIcon
                className=" h-3 w-3 mr-1"
                aria-hidden="true" />  Produk
            </button>
          </Stack>
        }
        bordered className='mb-8' >
        <CustomTable headers={["#", "Nama Produk", "Unit", ""]} headerClasses={[]} datasets={products.map(e => {
          return {
            cells: [
              { data: (products.indexOf(e) + 1) },
              { data: <div className="flex justify-between items-center hover:font-medium" style={{ width: "100%" }} onClick={() => nav(`/product/${e.uuid}`)} >{e.name}</div>, },
              { data: e.unit_name, },
              {
                data: <div className='flex justify-end ' >
                  <div onClick={() => {
                    pinnedMerchantProduct(e.uuid)
                      .then(e => {
                        getMerchantProducts()
                          .then(v => v.json())
                          .then(v => {
                            setProducts(v.data)
                          })
                      })
                  }}>
                    {e.is_pinned ?
                      <CheckCircleIcon className='w-5 h-5 text-green-700 mr-2' />
                      :
                      <XCircleIcon className='w-5 h-5 text-red-700 mr-2' />
                    }
                  </div>
                  <TrashIcon className="w-5 text-red-600" onClick={() => {
                    Swal.fire({
                      title: "Anda Yakin",
                      text: "Anda tidak akan dapat mengembalikan proses ini!",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Ya, Hapus!",
                      cancelButtonText: "Batal"
                    }).then((result) => {
                      if (result.isConfirmed) {
                        setIsLoading(true)
                        deleteMerchantProduct(e.uuid).then(v => getMerchantProducts()
                          .then(v => v.json())
                          .then(v => {
                            setProducts(v.data)
                          }))
                          .finally(() => setIsLoading(false))

                      }
                    });
                  }} /></div>, className: 'w-4 text-right'
              },
            ],
            className: "last:border-0 hover:bg-gray-50"
          }
        })} />
      </Panel>
    </div>
    {/* <div className='col-span-2'>
      <Panel
        header={
          <Stack justifyContent="space-between">
            <span>Jasa</span>
            <button
              type="button"
              className="mr-2 inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
              onClick={() => setModalServiceOpen(true)}
            >
              <PlusIcon
                className=" h-3 w-3 mr-1"
                aria-hidden="true" />  Tambah Jasa
            </button>
          </Stack>
        }
        bordered className='mb-8' >
      </Panel>
    </div> */}
  </div>
  )

  const storeCashier = () => (<div className='grid gap-x-8 gap-y-4 sm:gap-y-0 xl:grid-cols-4 lg:grid-cols-4 sm:grid-cols-2 md:grid-cols-2'>
    <div className='col-span-2'>
      <Panel
        header={
          <Stack justifyContent="space-between">
            <span>Gudang</span>
            <button
              type="button"
              className="mr-2 inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
              onClick={() => setModalStoreOpen(true)}
            >
              <PlusIcon
                className=" h-3 w-3 mr-1"
                aria-hidden="true" />  Gudang
            </button>
          </Stack>
        }
        bordered className='mb-8' >
        <CustomTable headers={["Nama Gudang", "Kontak Person", ""]} headerClasses={[]} datasets={merchantStores.map(e => {
          return {
            cells: [
              {
                data: e.name
              },
              {
                data: e.contact_person
              },
              {
                data: <TrashIcon className="w-5 text-red-600" onClick={() => {
                  Swal.fire({
                    title: "Anda Yakin",
                    text: "Anda tidak akan dapat mengembalikan proses ini!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Ya, Hapus!",
                    cancelButtonText: "Batal"
                  }).then((result) => {
                    if (result.isConfirmed) {
                      setIsLoading(true)
                      deleteMerchantStore(e.uuid!).then(v => getMerchantStores()
                        .then(v => v.json())
                        .then(v => {
                          setMerchantStores(v.data)
                        }))
                        .finally(() => setIsLoading(false))
                    }
                  });
                }} />, className: 'w-4 text-right'
              },
            ],
            className: "last:border-0 hover:bg-gray-50"
          }
        })} />
      </Panel>
    </div>
    <div className='col-span-2'>
      <Panel
        header={
          <Stack justifyContent="space-between">
            <span>Kasir</span>
            <button
              type="button"
              className="mr-2 inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
              onClick={() => setModalMerchantUserOpen(true)}
            >
              <PlusIcon
                className=" h-3 w-3 mr-1"
                aria-hidden="true" />  Kasir
            </button>
          </Stack>
        }
        bordered className='mb-8' >
        <CustomTable headers={["Nama", "Email", ""]} headerClasses={[]} datasets={merchantUsers.map(e => {
          return {
            cells: [
              {
                data: e.full_name
              },
              {
                data: e.email
              },
              {
                data: <TrashIcon className="w-5 text-red-600" onClick={() => {
                  Swal.fire({
                    title: "Anda Yakin",
                    text: "Anda tidak akan dapat mengembalikan proses ini!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Ya, Hapus!",
                    cancelButtonText: "Batal"
                  }).then((result) => {
                    if (result.isConfirmed) {
                      setIsLoading(true)
                      deleteMerchantUser(e.uuid!).then(v => getMerchantUsers()
                        .then(v => v.json())
                        .then(v => {
                          setMerchantUsers(v.data)
                        }))
                        .finally(() => setIsLoading(false))
                    }
                  });
                }} />, className: 'w-4 text-right'
              },
            ],
            className: "last:border-0 hover:bg-gray-50"
          }
        })} />
      </Panel>
    </div>
  </div>)

  const taxDiscount = () => (<div className='grid gap-x-8 gap-y-4 sm:gap-y-0 xl:grid-cols-4 lg:grid-cols-4 sm:grid-cols-2 md:grid-cols-2'>
    <div className='col-span-2'>
      <Panel
        header={
          <Stack justifyContent="space-between">
            <span>Pajak</span>
            <button
              type="button"
              className="mr-2 inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
              onClick={() => setModalTaxOpen(true)}
            >
              <PlusIcon
                className=" h-3 w-3 mr-1"
                aria-hidden="true" />  Pajak
            </button>
          </Stack>
        }
        bordered className='mb-8' >
        <CustomTable headers={["Pajak", "Nilai", ""]} headerClasses={[]} datasets={merchantTaxes.map(e => {
          return {
            cells: [
              {
                data: e.name
              },
              {
                data: `${e.amount}%`
              },
              {
                data: <TrashIcon className="w-5 text-red-600" onClick={() => {
                  Swal.fire({
                    title: "Anda Yakin",
                    text: "Anda tidak akan dapat mengembalikan proses ini!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Ya, Hapus!",
                    cancelButtonText: "Batal"
                  }).then((result) => {
                    if (result.isConfirmed) {
                      setIsLoading(true)
                      deleteMerchantTax(e.uuid!).then(v => getMerchantTaxes()
                        .then(v => v.json())
                        .then(v => {
                          setMerchantTaxes(v.data)
                        }))
                        .finally(() => setIsLoading(false))
                    }
                  });
                }} />, className: 'w-4 text-right'
              },
            ],
            className: "last:border-0 hover:bg-gray-50"
          }
        })} />
      </Panel>
    </div>
    <div className='col-span-2'>
      <Panel
        header={
          <Stack justifyContent="space-between">
            <span>Diskon</span>
            <button
              type="button"
              className="mr-2 inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
              onClick={() => setModalDiscountOpen(true)}
            >
              <PlusIcon
                className=" h-3 w-3 mr-1"
                aria-hidden="true" />  Diskon
            </button>
          </Stack>
        }
        bordered className='mb-8' >
        <CustomTable headers={["Nama", "Berlaku", "Keterangan", "Nilai", ""]} headerClasses={[]} datasets={merchantDiscounts.map(e => {
          return {
            cells: [
              {
                data: e.name
              },
              {
                data: <small className='flex flex-col justify-center'>
                  <Moment format='DD/MM/YYYY'>{e.start_date}</Moment>
                  <span className='text-center'>s/d</span>
                  <Moment format='DD/MM/YYYY'>{e.end_date}</Moment>
                </small>
              },
              {
                data: e.description
              },
              {
                data: e.type == 1 ? `${e.percent}%` : money(e.amount)
              },
              {
                data: <TrashIcon className="w-5 text-red-600" onClick={() => {
                  Swal.fire({
                    title: "Anda Yakin",
                    text: "Anda tidak akan dapat mengembalikan proses ini!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Ya, Hapus!",
                    cancelButtonText: "Batal"
                  }).then((result) => {
                    if (result.isConfirmed) {
                      setIsLoading(true)
                      deleteMerchantDiscount(e.uuid!).then(v => getMerchantDiscounts()
                        .then(v => v.json())
                        .then(v => {
                          setMerchantDiscounts(v.data)
                        }))
                        .finally(() => setIsLoading(false))
                    }
                  });
                }} />, className: 'w-4 text-right'
              },
            ],
            className: "last:border-0 hover:bg-gray-50"
          }
        })} />
      </Panel>
    </div>
  </div>)

  return (<div className="flex-1 mb-5 col-span-1 mt-5 rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer">
    {info()}
    {productService()}
    {storeCashier()}
    {categories()}
    {accountMachine()}
    {taxDiscount()}
    <ModalAccount isOpen={modalAccountOpen} setIsOpen={setModalAccountOpen} onClick={(val: Account) => {
      addMerchantAccount({ account_id: setNullString(val.uuid), type: val.type })
        .then(v => getMerchantAccounts()
          .then(v => v.json())
          .then(v => {
            setMerchantAccounts(v.data)
          }))
    }} />
    <ModalCustom title='Form Mesin' isOpen={modalMachineOpen} setIsOpen={setModalMachineOpen} onClose={() => {
      setInputDeposit(0)
      setInputMachineName("")
      setSelectedMachine(null)
    }}>
      <div className='flex h-full flex-col'>
        <div className='flex-1'>
          <InlineForm title={"Nama Mesin"} className='mt-4' >

            <input
              className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              type="text"
              placeholder={"contoh: Casio CX-100"}
              value={inputMachineName}
              onChange={(el) => setInputMachineName(el.target.value)}
            />

          </InlineForm>
          <InlineForm title={"Deposit"} >
            <CurrencyInput
              className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              groupSeparator="."
              decimalSeparator=","
              placeholder="Deposit"
              defaultValue={inputDeposit}
              onValueChange={(value, _, values) => {
                setInputDeposit(values?.float ?? 0)

              }}
            />
          </InlineForm>
        </div>

        <div className="mt-4">
          <button
            type="button"
            className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
            onClick={async () => {
              try {
                setIsLoading(true)
                if (selectedMachine) {
                  await updateMachine(selectedMachine!.uuid, {
                    name: inputMachineName,
                    deposit: inputDeposit,
                  })
                } else {
                  await addMachine({
                    name: inputMachineName,
                    deposit: inputDeposit,
                  })
                }

                getMerchantMachines()
                  .then(v => v.json())
                  .then(v => {
                    setMachines(v.data)
                  })
              } catch (error) {
                Swal.fire("Perhatian", `${error}`, "error")
              } finally {
                setInputDeposit(0)
                setInputMachineName("")
                setModalMachineOpen(false)
                setSelectedMachine(null)
                setIsLoading(false)
              }
            }}
          >
            <FolderIcon
              className=" h-5 w-5 mr-2"
              aria-hidden="true" />  Simpan
          </button>
        </div>
      </div>

    </ModalCustom>
    <ModalCustom title='Form Kategori Produk' isOpen={modalProductCategoryOpen} setIsOpen={setModalProductCategoryOpen} onClose={() => { }}>
      <InlineForm title={"Kategori"} className='mt-4'>
        <select
          className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
          onChange={(el) => {
            addMerchantProductCategory({ product_category_id: setNullString(el.target.value) })
              .then(v => {
                getMerchantProductCategories()
                  .then(v => v.json())
                  .then(v => {
                    setProductCategories(v.data)
                  })
              }).finally(() => setModalProductCategoryOpen(false))
          }}
        >
          <option value="">Pilih Kategori</option>
          {allProductCategories.map(e => <option key={e.uuid} value={e.uuid}>{e.name}</option>)}
        </select>
      </InlineForm>
    </ModalCustom>
    <ModalCustom title='Form Kategori Harga' isOpen={modalPriceCategoryOpen} setIsOpen={setModalPriceCategoryOpen} onClose={() => { }}>
      <InlineForm title={"Kategori"} className='mt-4'>
        <select
          className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
          onChange={(el) => {
            addMerchantPriceCategory({ price_category_id: setNullString(el.target.value) })
              .then(v => {
                getMerchantPriceCategories()
                  .then(v => v.json())
                  .then(v => {
                    setPriceCategories(v.data)
                  })
              }).finally(() => setModalPriceCategoryOpen(false))
          }}
        >
          <option value="">Pilih Kategori</option>
          {allPriceCategories.map(e => <option key={e.uuid} value={e.uuid}>{e.name}</option>)}
        </select>
      </InlineForm>

    </ModalCustom>
    <ModalCustom title='Pilih Produk' isOpen={modalProductOpen} setIsOpen={setModalProductOpen} onClose={() => {
      setModalProductOpen(false)
      setProductSelect(null)
      setUnitSelect(null)
      setIsProductPinned(false)
    }} >
      <div className='flex h-full flex-col'>
        <div className='flex-1'>
          <InlineForm title={"Produk"}>
            <Select< SelectOption, false> styles={colourStyles} options={allProducts.map(e => {
              return { value: e.uuid, label: e.name }
            })} value={productSelect}
              onChange={(option: SingleValue<SelectOption>): void => {
                let selected = allProducts.find(e => e.uuid == option?.value)
                setProductSelect(option)
                setUnits(selected?.units ?? [])
              }}
              onInputChange={(val) => {
                getProducts({ page: 1, limit: 10, isSell: true, search: val })
                  .then(v => v.json())
                  .then(v => {
                    setAllProducts(v.data)
                  })

              }}
            />
          </InlineForm>
          <InlineForm title={"Unit"}>
            <Select< SelectOption, false> styles={colourStyles} options={units.map(e => {
              return { value: e.uuid, label: e.name }
            })} value={unitSelect}
              onChange={(option: SingleValue<SelectOption>): void => {
                let selected = allProducts.find(e => e.uuid == option?.label)
                setUnitSelect(option)
              }}
            />
          </InlineForm>
          <InlineForm title={"Pinned"} className='mt-4'>
            <Switch
              checked={isProductPinned}
              onChange={setIsProductPinned}
              className={`${isProductPinned ? 'bg-blue-600' : 'bg-gray-200'
                } relative inline-flex h-6 w-11 items-center rounded-full`}
            >
              <span className="sr-only">Pinned</span>
              <span
                aria-hidden="true"
                className={`${isProductPinned ? 'translate-x-6' : 'translate-x-1'
                  } inline-block h-4 w-4 transform rounded-full bg-white transition`}
              />
            </Switch>
          </InlineForm>
        </div>
        <div className="mt-4">
          <button
            type="button"
            className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
            onClick={() => {
              let selected = allProducts.find(e => e.uuid == productSelect?.value)
              let selectedUnit = units.find(e => e.uuid == unitSelect?.value)
              addMerchantProduct({
                product_id: setNullString(selected?.uuid),
                product_category_id: setNullString(selected?.product_category_id),
                unit_id: setNullString(selectedUnit?.uuid),
                unit_name: selectedUnit?.name ?? "",
                unit_value: selectedUnit?.value ?? 0,
                notes_template: "{}",
                is_pinned: isProductPinned,

              }).then(v => getMerchantProducts()
                .then(v => v.json())
                .then(v => {
                  setProducts(v.data)
                  setModalProductOpen(false)
                  setProductSelect(null)
                  setUnitSelect(null)
                  setIsProductPinned(false)
                }))
            }}
          >
            <FolderIcon
              className=" h-5 w-5 mr-2"
              aria-hidden="true" />  Simpan
          </button>
        </div>
      </div>
    </ModalCustom>
    <ModalSearch isOpen={modalStoreOpen} setIsOpen={setModalStoreOpen} onChange={val => {
      getStores({ page: 1, limit: 10, search: val, })
        .then(v => v.json())
        .then(v => {
          setAllStores(v.data)
        })
    }} onClose={() => {
      setModalStoreOpen(false)
    }} >
      <ul role="list" className="divide-y divide-gray-100">
        {allStores.map(e => <li onClick={() => {
          setModalStoreOpen(false)
          addMerchantStore({ store_id: setNullString(e.uuid) })
            .then(v => {
              getMerchantStores()
                .then(v => v.json())
                .then(v => {
                  setMerchantStores(v.data)
                })
            })
        }} key={e.uuid} className="flex items-center gap-x-4 px-3 py-5 hover:bg-gray-600 outline-none cursor-pointer text-gray-900 hover:text-white">
          <Avatar marginRight={0} name={e.name} url={""} />
          <div className="min-w-0">
            <p className="text-sm font-semibold leading-6   ">{e.name}</p>
          </div>
        </li>)}
      </ul>
    </ModalSearch>
    <ModalSearch isOpen={modalMerchantUserOpen} setIsOpen={setModalMerchantUserOpen} onChange={val => {
      getCompanyMembers()
        .then(v => v.json())
        .then(v => {
          setAllMembers(v.data)
        })
    }} onClose={() => {
      setModalMerchantUserOpen(false)
    }} >
      <ul role="list" className="divide-y divide-gray-100">
        {allMembers.map(e => <li onClick={() => {
          setModalMerchantUserOpen(false)
          addMerchantUser({ cashier_id: setNullString(e.uuid) })
            .then(v => {
              getMerchantUsers()
                .then(v => v.json())
                .then(v => {
                  setMerchantUsers(v.data)
                })
            })
        }} key={e.uuid} className="flex items-center gap-x-4 px-3 py-5 hover:bg-gray-600 outline-none cursor-pointer text-gray-900 hover:text-white">
          <Avatar marginRight={0} name={e.full_name} url={""} />
          <div className="min-w-0">
            <p className="text-sm font-semibold leading-6   ">{e.full_name}</p>
            <p className="text-xs font-base leading-6   ">{e.email}</p>
          </div>
        </li>)}
      </ul>
    </ModalSearch>
    <ModalCustom title='Form Pajak' isOpen={modalTaxOpen} setIsOpen={setModalTaxOpen} onClose={() => { }}>
      <InlineForm title={"Pajak"} className='mt-4'>
        <select
          className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
          onChange={(el) => {
            addMerchantTax({ tax_id: setNullString(el.target.value) })
              .then(v => {
                getMerchantTaxes()
                  .then(v => v.json())
                  .then(v => {
                    setMerchantTaxes(v.data)
                  })
              }).finally(() => setModalTaxOpen(false))
          }}
        >
          <option value="">Pilih Pajak</option>
          {taxes.map(e => <option key={e.uuid} value={e.uuid}>{e.name} ({e.amount}%)</option>)}
        </select>
      </InlineForm>
    </ModalCustom>
    <ModalCustom title='Form Diskon' isOpen={modalDiscountOpen} setIsOpen={setModalDiscountOpen} onClose={() => { }}>
      <InlineForm title={"Diskon"} className='mt-4'>
        <select
          className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
          onChange={(el) => {
            addMerchantDiscount({ discount_id: setNullString(el.target.value) })
              .then(v => {
                getMerchantDiscounts()
                  .then(v => v.json())
                  .then(v => {
                    setMerchantDiscounts(v.data)
                  })
              }).finally(() => setModalDiscountOpen(false))
          }}
        >
          <option value="">Pilih Diskon</option>
          {allDiscounts.map(e => <option key={e.uuid} value={e.uuid}>{e.name} ({e.type == 1 ? `${e.percent}%` : money(e.amount)})</option>)}
        </select>
      </InlineForm>
    </ModalCustom>
  </div>);
}
export default CashierSetting;