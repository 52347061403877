import { ContactReq } from "../model/company";
import { PaginationReq } from "../model/pagination";
import { customFetch } from "../utils/helper";
import moment from "moment";
import { DateRange } from "rsuite/esm/DateRangePicker";

export let getVendors = async (pagination: PaginationReq) => {
    var params: Record<string, string> = {
        page: pagination.page.toString(),
        limit: pagination.limit.toString(),

    };
    if (pagination.search && pagination.search != "") {
        params["search"] = pagination.search
    }
   
    return await customFetch(`Vendor/?${new URLSearchParams(params)}`)
}

export let addVendor = async (req: ContactReq) => {

    return await customFetch(`Vendor/`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}
export let updateVendor = async (uuid: string, req: ContactReq) => {

    return await customFetch(`Vendor/${uuid}`, {
        method: "PUT",
        body: JSON.stringify(req)
    })
}

export let deleteVendor = async (uuid: string) => {
    return await customFetch(`Vendor/${uuid}`, {
        method: "DELETE",
    })
}

export let getVendorDetail = async (uuid: string) => {
    return await customFetch(`Vendor/${uuid}`, {
    })
}
