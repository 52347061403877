import React, { Suspense, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { AppRoute } from './routes/AppRoute';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { CustomProvider } from 'rsuite';
import { LoadingContext } from './objects/loading_context';
import idID from "./objects/id_ID";
import 'rsuite/dist/rsuite-no-reset.min.css';


function App() {
  const [mounted, setMounted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false)
  const [splash, setSplash] = useState<boolean>(true);
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_API_CLIENT_API!}  >
      <Suspense>
        <CustomProvider locale={idID}>
          <LoadingContext.Provider value={{ isLoading: loading, setIsLoading: setLoading }}>
            <AppRoute />
          </LoadingContext.Provider>
        </CustomProvider>
      </Suspense>
    </GoogleOAuthProvider>
  );
}

export default App;
