import { PaginationReq } from "../model/pagination";
import { ProductRequestItem, Store, StoreReq, StoreRequestItemReq, StoreRequestReq } from "../model/store";
import { customFetch } from "../utils/helper";

export let getStores = async (pagination: PaginationReq) => {
    var params: Record<string, string> = {
        page: pagination.page.toString(),
        limit: pagination.limit.toString(),
        search: pagination.search ?? "",

    };
    return await customFetch(`Store/?${new URLSearchParams(params)}`)
}
export let getStoreProducts = async (uuid: string, pagination: PaginationReq) => {
    var params: Record<string, string> = {
        page: pagination.page.toString(),
        limit: pagination.limit.toString(),
        search: pagination.search ?? "",

    };
    return await customFetch(`Store/${uuid}/Product?${new URLSearchParams(params)}`)
}
export let getStoreProductDetail = async (uuid: string, productId: string) => {
    return await customFetch(`Store/${uuid}/Product/${productId}`)
}

export let getStoreDetail = async (uuid: string) => {
    return await customFetch(`Store/${uuid}`)
}

export let deleteStore = async (uuid: string) => {
    return await customFetch(`Store/${uuid}`,{
        method: "DELETE"
    })
}
export let getStorePermissions = async (uuid: string) => {
    return await customFetch(`Store/${uuid}/Permission`)
}
export let getStoreRequests = async (uuid: string, pagination: PaginationReq) => {
    var params: Record<string, string> = {
        page: pagination.page.toString(),
        limit: pagination.limit.toString(),
        search: pagination.search ?? "",

    };
    return await customFetch(`ProductRequest/?${new URLSearchParams(params)}&store_id=${uuid}`)
}

export let getStoreRequestDetail = async (uuid: string) => {
    return await customFetch(`ProductRequest/${uuid}`)
}

export let addStoreRequest = async (req: StoreRequestReq) => {
    return await customFetch(`ProductRequest/`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}
export let processStoreRequest = async (uuid: string, remarks: string) => {
    return await customFetch(`ProductRequest/${uuid}/Process`, {
        method: "PUT",
        body: JSON.stringify({
            remarks
        })
    })
}
export let rejectStoreRequest = async (uuid: string, remarks: string) => {
    return await customFetch(`ProductRequest/${uuid}/Reject`, {
        method: "PUT",
        body: JSON.stringify({
            remarks
        })
    })
}
export let finishStoreRequest = async (uuid: string, remarks: string) => {
    return await customFetch(`ProductRequest/${uuid}/Finish`, {
        method: "PUT",
        body: JSON.stringify({
            remarks
        })
    })
}

export let addStoreRequestItem = async (uuid: string, req: StoreRequestItemReq) => {
    return await customFetch(`ProductRequest/${uuid}/AddItem`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}

export let deleteStoreRequestItem = async (uuid: string, itemId: string) => {
    return await customFetch(`ProductRequest/${uuid}/DeleteItem/${itemId}`, {
        method: "DELETE",
    })
}

export let deleteStoreUser = async (uuid: string, userId: string) => {
    return await customFetch(`Store/${uuid}/DeleteUser/${userId}`, {
        method: "DELETE"
    })
}
export let addStoreUser = async (uuid: string, userId: string, permissions: string[]) => {
    return await customFetch(`Store/${uuid}/AddUser`, {
        method: "POST",
        body: JSON.stringify({
            user_id: userId,
            permissions
        })
    })
}
export let getStoreUsers = async (uuid: string, pagination: PaginationReq) => {
    var params: Record<string, string> = {
        page: pagination.page.toString(),
        limit: pagination.limit.toString(),
        search: pagination.search ?? "",

    };
    return await customFetch(`Store/${uuid}/User?${new URLSearchParams(params)}`)
}

export let addStore = async (req: StoreReq) => {
    return await customFetch(`Store/`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}
export let updateStore = async (uuid: string, req: StoreReq) => {
    return await customFetch(`Store/${uuid}`, {
        method: "PUT",
        body: JSON.stringify(req)
    })
}