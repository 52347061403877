import { useState, type FC, useEffect } from 'react';
import ModalSearch from './modal_search';
import { Account } from '../../model/account';
import { getAccounts } from '../../repositories/account';
import Avatar from '../avatar';
import { COLORS } from '../../objects/constants';

interface ModalAccountProps {
    children?: React.ReactNode
    accountType?: string
    cashflowGroup?: string
    cashflowSubgroup?: string
    search?: string
    isOpen: boolean
    setIsOpen: (arg: boolean) => void
    onClose?: () => void
    onClick: (arg: Account) => void
}

const ModalAccount: FC<ModalAccountProps> = ({
    children,
    accountType,
    cashflowGroup,
    cashflowSubgroup,
    isOpen,
    setIsOpen,
    onClose,
    onClick,
}) => {
    const [mounted, setMounted] = useState(false);
    const [search, setSearch] = useState("");
    const [accounts, setAccounts] = useState<Account[]>([]);
    
    useEffect(() => {
        setMounted(true)
    }, []);
    
    useEffect(() => {
        if (mounted) return
        getAllAccounts()
    }, [mounted]);

    useEffect(() => {
        getAllAccounts()
    }, [search]);
    const getAllAccounts = async () => {
        getAccounts({
            page: 1, 
            limit: 10,
            search,
            type: accountType,
            cashflowGroup,
            cashflowSubgroup
        })
        .then(v => v.json())
        .then(v => setAccounts(v.data))
    }

    

    return (<ModalSearch isOpen={isOpen} onClose={onClose} setIsOpen={setIsOpen} onChange={(val) => setSearch(val)}>
        <ul role="list" className="divide-y divide-gray-100">
                {accounts.map((v, index) => {
                    let color = "gray"
                    switch (v.type) {
                        case "Asset":
                            color = COLORS.filter(e => e.name == "DeepSkyBlue")[0].name
                            break;
                        case "Equity":
                            color = COLORS.filter(e => e.name == "Azure")[0].name
                            break;
                        case "Income":
                            color = COLORS.filter(e => e.name == "BlueViolet")[0].name
                            break;
                        case "Expense":
                            color = COLORS.filter(e => e.name == "Brown")[0].name
                            break;
                        case "Receivable":
                            color = COLORS.filter(e => e.name == "Chocolate")[0].name
                            break;
                        case "Cost":
                            color = COLORS.filter(e => e.name == "Gold")[0].name
                            break;
                        case "Liability":
                        case "Payable":
                            color = COLORS.filter(e => e.name == "DeepPink")[0].name
                            break;
                    
                        default:
                            break;
                    }
                    
                    return (
                        <li key={v.uuid} onClick={() => {
                            onClick(v);
                            setIsOpen(false);
                            if (onClose != null) onClose()
                        } }
                            className="flex items-center gap-x-4 px-3 py-5 hover:bg-gray-600 outline-none cursor-pointer text-gray-900 hover:text-white"
                        >
                            <Avatar marginRight={0} name={v.name} url={''} style={{ backgroundColor: color }} />
                            <div className="min-w-0">
                                <p className="text-sm font-semibold leading-6   ">{v.name}</p>
                                {v.type.length > 0 && <p className="-mt-0.5 truncate text-xs leading-5  ">{v.type}</p>}
                                {v.cashflow_group_label.length > 0 && <p className="-mt-0.5 truncate text-xs leading-5  ">{v.cashflow_group_label}</p>}
                                {v.cashflow_subgroup_label.length > 0 && <p className="-mt-0.5 truncate text-xs leading-5  ">{v.cashflow_subgroup_label}</p>}
                            </div>
                        </li>
                    );
                })}
            </ul>
        {children}
    </ModalSearch>);
}
export default ModalAccount;