import { Fragment, useEffect, useState, type FC } from 'react';
import { DAILY, MONTHLY, SELECTED_MERCHANT_ID, TIMERANGES, WEEKLY, YEARLY } from '../objects/constants';
import { dateRangeChart, dateRangeProgress } from '../objects/dashboard';
import { ActiveProduct, ChartData, SaleBuyProgress, TopProduct, TransactionProgress } from '../model/dashboard';
import { useNavigate } from 'react-router-dom';
import { CustomerService } from '../model/company';
import { getPermissions, getSelectedMerchantID, randomStr, reverseArr, setMyPermissions } from '../utils/helper';
import { getCustomerServices, getMyPermissions } from '../repositories/company';
import { getBuyProgress, getChartData, getProductActive, getSaleProgress, getTopProducts, getTransactionProgress } from '../repositories/dashboard';
import moment from 'moment';
import { Progress, Tooltip, Whisper } from 'rsuite';
import { money } from '../utils/number';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { CustomChart } from '../components/custom_chart';
import { CustomPie } from '../components/custom_pie';
import Moment from 'react-moment';
import DashboardLayout from '../components/dashboard';
import ModalCustom from '../components/modals/modal_custom';
import Avatar from '../components/avatar';

interface HomeProps { }

const Home: FC<HomeProps> = ({ }) => {
    const [range, setRange] = useState(DAILY);
    const [permissions, setPermissions] = useState<string[]>([]);
    const [dateRangeProgress, setDateRangeProgress] = useState<dateRangeProgress | null>(null);
    const [merchantId, setMerchantId] = useState<string | null>(null);
    const [saleProgress, setSaleProgress] = useState<SaleBuyProgress | null>(null);
    const [buyProgress, setBuyProgress] = useState<SaleBuyProgress | null>(null);
    const [transactionProgress, setTransactionProgress] = useState<TransactionProgress | null>(null);
    const [chartDateRanges, setChartDateRanges] = useState<dateRangeChart[]>([]);
    const [chartDatas, setChartDatas] = useState<ChartData | null>(null);
    const nav = useNavigate()
    const [topProducts, setTopProducts] = useState<TopProduct[]>([]);
    const [customerServices, setCustomerServices] = useState<CustomerService[]>([]);
    const [modalCSOpen, setModalCSOpen] = useState(false);
    const [activeProduct, setActiveProduct] = useState<ActiveProduct | null>(null);

    useEffect(() => {
        getSelectedMerchantID().then((v) => {
            setMerchantId(v)
        })

    }, []);

    useEffect(() => {
        if (!merchantId) return
        initData()
    }, [merchantId]);

    const initData = async () => {
        setPermissions(await getPermissions())
        getMyPermissions()
            .then(v => v.json())
            .then(v => {
                setMyPermissions(v.data)
            })

        getTopProducts()
            .then(v => v.json())
            .then(v => {
                let products: TopProduct[] = []
                for (let index = 0; index < v.data.length; index++) {
                    const element: TopProduct = v.data[index];
                    products.push(element)
                }
                let total = products.map(e => e.sold).reduce((a, b) => a + b, 0)

                products = products.map(e => {
                    e.percent = Math.round(e.sold / total * 100)
                    return e
                })
                setTopProducts([...products])
            })


        getCustomerServices()
            .then(v => v.json())
            .then(v => {
                setCustomerServices(v.data)
            })

        getProductActive()
            .then(v => v.json())
            .then(v => {
                setActiveProduct(v.data)
            })

    }
    useEffect(() => {
        getAllReport()
    }, [dateRangeProgress]);
    useEffect(() => {
        setDateRangeProgress({
            ...dateRangeProgress!,
            merchant_id: merchantId,
        })
    }, [merchantId]);

    useEffect(() => {
        getChartData(chartDateRanges)
            .then(v => v.json())
            .then(v => {
                setChartDatas(v.data)
            })
    }, [chartDateRanges]);

    useEffect(() => {
        if (range == DAILY) {
            var now = moment().local();
            setDateRangeProgress({
                start_date: moment().toDate(),
                end_date: moment().toDate(),
                start_date_before: moment().subtract(1, 'days').toDate(),
                end_date_before: moment().subtract(1, 'days').toDate(),
                merchant_id: merchantId,
            })
            var ranges: dateRangeChart[] = []
            for (let index = 0; index <= 6; index++) {
                ranges.push({
                    start_date: now.clone().weekday(index).toDate(),
                    end_date: now.clone().weekday(index).toDate(),
                    label: now.clone().weekday(index).locale("id").format("dddd"),
                    merchant_id: merchantId,
                })
            }
            setChartDateRanges(ranges)
        }
        if (range == WEEKLY) {
            var now = moment().local();
            setDateRangeProgress({
                start_date: now.clone().weekday(0).toDate(),
                end_date: now.clone().weekday(6).toDate(),
                start_date_before: now.clone().subtract(1, 'weeks').startOf('week').toDate(),
                end_date_before: now.clone().subtract(1, 'weeks').endOf('week').toDate(),
                merchant_id: merchantId,
            })

            let firstDay = now.clone().startOf('month').date()
            let endDay = now.clone().endOf('month').date()
            let weekdays: { week: number }[] = []
            var ranges: dateRangeChart[] = []
            let i = 1
            for (let index = firstDay; index <= endDay; index++) {
                let date = moment(`${now.year()}-${now.month() + 1}-${index}`)
                let weekNumber = date.week()
                if (!(weekdays.find(e => e.week == weekNumber))) {
                    weekdays.push({ week: weekNumber })
                    ranges.push({
                        start_date: date.startOf('week').toDate(),
                        end_date: date.endOf('week').toDate(),
                        label: `Minggu #${i}`,
                        merchant_id: merchantId,
                    })
                    i++
                }

            }
            setChartDateRanges(ranges)
        }
        if (range == MONTHLY) {
            var now = moment().local();
            setDateRangeProgress({
                start_date: now.clone().startOf('month').toDate(),
                end_date: now.clone().endOf('month').toDate(),
                start_date_before: now.clone().subtract(1, 'months').startOf('month').toDate(),
                end_date_before: now.clone().subtract(1, 'months').endOf('month').toDate(),
                merchant_id: merchantId,
            })
            var ranges: dateRangeChart[] = []
            for (let index = 0; index < 12; index++) {

                let month = moment(`${now.year()}-${index + 1}-1`)
                ranges.push({
                    start_date: month.toDate(),
                    end_date: month.endOf("month").toDate(),
                    label: month.locale("id").format("MMMM"),
                    merchant_id: merchantId,
                })
            }

            setChartDateRanges(ranges)

        }
        if (range == YEARLY) {
            var now = moment().local();
            setDateRangeProgress({
                start_date: now.clone().startOf('year').toDate(),
                end_date: now.clone().endOf('year').toDate(),
                start_date_before: now.clone().subtract(1, 'years').startOf('year').toDate(),
                end_date_before: now.clone().subtract(1, 'years').endOf('year').toDate(),
                merchant_id: merchantId,
            })

            var ranges: dateRangeChart[] = []
            for (let index = 0; index < 5; index++) {
                let year = index > 0 ? now.clone().subtract(index, 'years') : now.clone()
                ranges.push({
                    start_date: year.startOf('year').toDate(),
                    end_date: year.endOf('year').toDate(),
                    label: year.format("YYYY"),
                    merchant_id: merchantId,
                })


            }

            setChartDateRanges(reverseArr(ranges))
        }
    }, [range]);

    const getAllReport = async () => {
        if (!dateRangeProgress) return
        getSaleProgress(dateRangeProgress!)
            .then(v => v.json())
            .then(v => setSaleProgress(v.data))
        getBuyProgress(dateRangeProgress!)
            .then(v => v.json())
            .then(v => setBuyProgress(v.data))
        getTransactionProgress(dateRangeProgress!)
            .then(v => v.json())
            .then(v => setTransactionProgress(v.data))

    }

    const saleCard = () => {
        let label = ""
        switch (range) {
            case DAILY:
                label = "Hari ini"
                break;
            case WEEKLY:
                label = "Minggu ini"
                break;
            case MONTHLY:
                label = "Bulan ini"
                break;
            case YEARLY:
                label = "Tahun ini"
                break;

        }
        return (saleProgress &&
            <div className="">
                <div className="mb-5 rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer">
                    <div className="mx-auto mb-5  flex items-center">
                        <Whisper followCursor speaker={<Tooltip>Total pembelian {label}</Tooltip>}>
                            <div className="relative inline-flex items-center justify-center w-8 h-8 overflow-hidden bg-green-600 rounded-full mr-2">
                                <img src="/images/sale-disabled.png" alt="" className="invert w-4 h-4 " />
                            </div>
                        </Whisper>
                        <p className="mb-0 text-xl font-medium text-green-600">
                            Penjualan
                        </p>
                    </div>
                    <h1 className=" text-4xl font-medium text-gray-600">{money(saleProgress.current)}</h1>
                    <div className="flex">
                        {saleProgress.progress > 0 && <img src="/images/increase.png" alt="" className="w-4 h-4 mr-2" />}
                        {saleProgress.progress < 0 && <img src="/images/decrease.png" alt="" className="w-4 h-4 mr-2" />}
                        {saleProgress.progress == 0 && <div className="w-4 h-4 mr-2" />}

                        <p className="mb-0 text-sm sm:text-xs text-gray-800">{saleProgress.progress != 0 ? `${money(saleProgress.progress)}% dari periode sebelumnya` : ''}</p>

                    </div>
                    <div>
                    </div>
                </div>
            </div>
        )
    }

    const transactionCard = () => {
        return (transactionProgress &&
            <div className="">
                <div className="mb-5 rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer">
                    <div className="mx-auto mb-5  flex items-center">
                        <Whisper followCursor speaker={<Tooltip>Total transaksi pembelian, penjualan dan kasir</Tooltip>}>
                            <div className="relative inline-flex items-center justify-center w-8 h-8 overflow-hidden bg-blue-600 rounded-full mr-2">
                                <img src="/images/transaction-disabled.png" alt="" className="invert w-4 h-4 " />
                            </div>
                        </Whisper>
                        <p className="mb-0 text-xl font-medium text-blue-600">
                            Transaksi
                        </p>
                    </div>
                    <h1 className=" text-4xl font-medium text-gray-600">{money(transactionProgress.count)}</h1>
                    <div className="flex">
                        {transactionProgress.progress > 0 && <img src="/images/increase.png" alt="" className="w-4 h-4 mr-2" />}
                        {transactionProgress.progress < 0 && <img src="/images/decrease.png" alt="" className="w-4 h-4 mr-2" />}
                        {transactionProgress.progress == 0 && <div className="w-4 h-4 mr-2" />}

                        <p className="mb-0 text-sm sm:text-xs text-gray-800">{transactionProgress.progress != 0 ? `${money(transactionProgress.progress)}% dari periode sebelumnya` : ''}</p>

                    </div>
                    <div>
                    </div>
                </div>
            </div>
        )
    }
    const productCard = () => {
        return (activeProduct != null &&
            <div className="">
                <div className="mb-5 rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer">
                    <div className="mx-auto mb-5  flex items-center">
                        <Whisper followCursor speaker={<Tooltip>Produk aktif yang terdaftar di merchant aktif, berbanding dengan produk yang terdaftar dikeseluruhan perusahaan. produk aktif digunakan di menu kasir, silakan buka pengaturan kasir untuk menambah dan mengurangi</Tooltip>}>
                            <div className="relative inline-flex items-center justify-center w-8 h-8 overflow-hidden bg-orange-600 rounded-full mr-2">
                                <img src="/images/product-disabled.png" alt="" className="invert w-4 h-4 " />
                            </div>
                        </Whisper>
                        <p className="mb-0 text-xl font-medium text-orange-600">
                            Produk Aktif
                        </p>
                    </div>
                    <h1 className=" text-4xl font-medium text-gray-600">{money(activeProduct.active_product)}</h1>
                    <div className="flex">
                        <p className="mb-0 text-sm sm:text-xs text-gray-800">dari {money(activeProduct.total_product)} produk</p>
                    </div>
                    <div>
                    </div>
                </div>
            </div>
        )
    }
    const buyCard = () => {
        let label = ""
        switch (range) {
            case DAILY:
                label = "Hari ini"
                break;
            case WEEKLY:
                label = "Minggu ini"
                break;
            case MONTHLY:
                label = "Bulan ini"
                break;
            case YEARLY:
                label = "Tahun ini"
                break;

        }

        return (buyProgress &&
            <div className="">
                <div className="mb-5  rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer">
                    <div className="mx-auto mb-5  flex items-center">
                        <Whisper followCursor speaker={<Tooltip>Total pembelian {label}</Tooltip>}>
                            <div className="relative inline-flex items-center justify-center w-8 h-8 overflow-hidden bg-red-600 rounded-full mr-2">
                                <img src="/images/buy-disabled.png" alt="" className="invert w-4 h-4 " />
                            </div>
                        </Whisper>
                        <p className="mb-0 text-xl font-medium text-red-600">
                            Pembelian
                        </p>
                    </div>
                    <h1 className=" text-4xl font-medium text-gray-600">{money(buyProgress.current)}</h1>
                    <div className="flex">
                        {buyProgress.progress > 0 && <img src="/images/increase.png" alt="" className="w-4 h-4 mr-2" />}
                        {buyProgress.progress < 0 && <img src="/images/decrease.png" alt="" className="w-4 h-4 mr-2" />}
                        {buyProgress.progress == 0 && <div className="w-4 h-4 mr-2" />}

                        <p className="mb-0 text-sm sm:text-xs text-gray-800">{buyProgress.progress != 0 ? `${money(buyProgress.progress)}% dari periode sebelumnya` : ''}</p>

                    </div>
                    <div>
                    </div>
                </div>
            </div>
        )
    }

    const getLabel = (val: string) => {
        return TIMERANGES.filter(e => e.value == val)[0].label
    }


    const menuTimeRange = () => (
        <div className="text-right mr-2">
            <Menu as="div" className="relative inline-block text-left">
                <div>
                    <Menu.Button className="inline-flex w-full justify-center rounded-md bg-white px-4 py-2 text-sm font-medium  hover:bg-black/30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
                        {getLabel(range)}
                        <ChevronDownIcon
                            className="-mr-1 ml-2 h-5 w-5 text-violet-200 hover:text-violet-100"
                            aria-hidden="true"
                        />
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="absolute z-10 right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                        <div className="px-1 py-1 ">
                            {TIMERANGES.map(e => (
                                <Menu.Item key={e.value}>
                                    {({ active }) => (
                                        <button
                                            onClick={() => setRange(e.value)}
                                            className={`${active || range == e.value ? 'bg-pink-500 text-white' : 'text-gray-900'
                                                } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                        >
                                            {e.label}
                                        </button>
                                    )}
                                </Menu.Item>
                            ))}


                        </div>

                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    )
    const menuDataAll = () => (
        <div className="text-right">
            <Menu as="div" className="relative inline-block text-left">
                <div>
                    <Menu.Button className="inline-flex w-full justify-center rounded-md bg-white px-4 py-2 text-sm font-medium  hover:bg-black/30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
                        {merchantId == null ? "Semua Data" : "Data Merchant"}
                        <ChevronDownIcon
                            className="-mr-1 ml-2 h-5 w-5 text-violet-200 hover:text-violet-100"
                            aria-hidden="true"
                        />
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="absolute z-10 right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                        <div className="px-1 py-1 ">
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        onClick={() => setMerchantId(null)}
                                        className={`${merchantId == null ? 'bg-pink-500 text-white' : 'text-gray-900'
                                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                    >
                                        Semua Data
                                    </button>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        onClick={async () => setMerchantId(await getSelectedMerchantID())}
                                        className={`${merchantId != null ? 'bg-pink-500 text-white' : 'text-gray-900'
                                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                    >
                                        Data Merchant
                                    </button>
                                )}
                            </Menu.Item>

                        </div>

                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    )




    const chartCard = () => {

        return (chartDatas != null &&
            <div className="mb-5 h-auto rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer">
                <h3 className=" text-2xl mb-4">Penjualan & Pembelian</h3>

                <CustomChart labels={chartDatas.labels} datasets={[
                    {
                        label: "Penjualan",
                        data: chartDatas.sales,
                        backgroundColor: "rgba(22,163,74,0.7)"
                    },
                    {
                        label: "Pembelian",
                        data: chartDatas.buys,
                        backgroundColor: "rgba(220,38,38,0.7)"
                    }
                ]} />


            </div>
        );
    }

    const productPieCard = () => {

        return (

            <div className="mb-5 h-auto rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer">
                <h3 className=" text-2xl mb-4">Produk Terlaris</h3>
                <div className="grid grid-cols-2 gap-y-2">
                    <div className=" w-full">
                        <CustomPie labels={topProducts.map(e => e.name)} datasets={[
                            {
                                label: '# of Transaksi',
                                data: topProducts.map(e => e.sold),
                                backgroundColor: [
                                    'rgba(255, 99, 132, 0.7)',
                                    'rgba(54, 162, 235, 0.7)',
                                    'rgba(255, 206, 86, 0.7)',
                                    'rgba(75, 192, 192, 0.7)',
                                    'rgba(153, 102, 255, 0.7)',
                                    'rgba(255, 159, 64, 0.7)',
                                ],

                            },
                        ]} />

                    </div>

                    <div>
                        <ul>
                            {topProducts.map(e => <li key={e.uuid} className="flex w-full items-center mb-2">
                                <span className="text-bold text-5xl mr-2">{topProducts.indexOf(e) + 1}</span>
                                <div className="flex flex-col flex-1">
                                    <span className="text-bold text-lg px-2">{e.name}</span>
                                    <Progress.Line percent={e.percent} strokeColor={["blue", "red", "orange", "lime", "slate"][topProducts.indexOf(e)]} />
                                </div>

                            </li>)}
                        </ul>
                        <p className="text-sm text-gray-400 text-right">Produk berdasarkan perjualan 5 teratas</p>
                    </div>
                </div>
            </div>
        );
    }


    const supportCard = () => (
        <div className="mb-5 overflow-y-auto  rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer" style={{
            height: "40vh",
            backgroundImage: "url('/images/bg-trading.jpg",
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
        }}>
            <div className="flex flex-col justify-between h-full items-end">
                <p className="text-white sm:text-xl xl:text-4xl text-extrabold [text-shadow:2px_2px_4px_var(--tw-shadow-color)] shadow-gray-700">Hubungi Kami jika anda membutuhkan bantuan</p>
                <div className="py-2 px-4 bg-white rounded-full w-fit flex items-center">
                    <img src="/images/customer-agent.png" className="w-10 h-10 mr-2" alt="" />
                    <span className=" sm:text-base xl:text-xl" onClick={() => setModalCSOpen(true)}> Jaraya Support</span>
                </div>
            </div>
        </div>
    )
    const latestTransaction = () => (
        <div className="mb-5 flex flex-col col-span-3 rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer" style={{ height: "40vh" }}>
            <div className="">
                <h3 className="text-2xl mb-4 ">Transaksi Terbaru</h3>
            </div>
            <div className="flex-1 relative overflow-y-auto overflow-x-auto">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                        <tr>
                            <th scope="col" className="px-6 py-3 w-10">
                                Tgl
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Keterangan
                            </th>
                            <th scope="col" className="px-6 py-3 text-right">
                                Total
                            </th>

                        </tr>
                    </thead>
                    <tbody className="">
                        {transactionProgress?.result.length == 0 && <tr><td colSpan={3} className="p-8 text-center">No Data</td></tr>}
                        {transactionProgress?.result.map(e => (<tr key={randomStr(3)} className="bg-white border-b  ">
                            <td className="px-6 py-4 text-right">
                                <Moment format="DD/MM/YYYY">{e.date}</Moment>
                            </td>
                            <td
                                scope="row"
                                className="px-6 py-4 font-base  hover:font-medium text-gray-900 whitespace-nowrap"
                            >
                                <span onClick={() => {
                                    if (e.type == "bill") {
                                        nav(`/buy/${e.uuid}`)
                                    }
                                    if (e.type == "invoice") {
                                        nav(`/sale/${e.uuid}`)
                                    }
                                    if (e.type == "merchant") {
                                        // nav(`/pos/${e.uuid}`)
                                        window.open(`/pos/${e.uuid}/receipt`)
                                    }
                                }}>

                                    {e.description}
                                </span>
                            </td>
                            <td className="px-6 py-4 text-right">{money(e.grand_total)}</td>
                        </tr>))}

                    </tbody>
                </table>
            </div>

        </div>
    )

    return (
        <DashboardLayout permission="menu_dashboard" >
            <div className="p-6">
                <div className="flex justify-between">
                    <h1 className="text-4xl">Beranda</h1>
                    <div className="flex">
                        {menuTimeRange()}
                        {menuDataAll()}

                    </div>
                </div>
                <div className="mt-6 grid gap-x-8 gap-y-4 sm:gap-y-0 xl:grid-cols-4 lg:grid-cols-4 sm:grid-cols-2 md:grid-cols-2 ">
                    {saleCard()}
                    {buyCard()}
                    {transactionCard()}
                    {productCard()}
                </div>
                <div className="mt-2 grid gap-x-8 gap-y-4 sm:gap-y-0 xl:grid-cols-2 lg:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 ">
                    {chartCard()}
                    {productPieCard()}
                </div>
                <div className="mt-2 grid gap-x-8 gap-y-4 sm:gap-y-0 xl:grid-cols-4 lg:grid-cols-4 sm:grid-cols-2 md:grid-cols-2 ">
                    {latestTransaction()}
                    {supportCard()}
                </div>


            </div>

            <ModalCustom height={400} width={400} title="Customer Service" isOpen={modalCSOpen} setIsOpen={setModalCSOpen} >
                <p>Customer Service kami siap membantu anda</p>
                <div className="mt-4">
                    <ul>
                        {customerServices.map(e => <li key={e.id}>
                            <div className="flex justify-between cursor-pointer" onClick={() => {
                                if (e.is_online) {
                                    window.open(`https://wa.me/${e.phone}`)
                                }
                            }}>
                                <div>
                                    <h3 className="-mb-1 text-2xl font-semibold">{e.name}</h3>
                                    <p className="-mb-2">{e.position}</p>
                                    {e.is_online && <small className=" text-gray-600 text-green-900">online</small>}

                                </div>
                                <div>
                                    <Avatar name={e.name} url={e.photo} />
                                </div>
                            </div>

                        </li>)}
                    </ul>
                </div>
            </ModalCustom>

        </DashboardLayout>
    );
}
export default Home;