import { useContext, useEffect, useState, type FC } from 'react';
import { LoadingContext } from '../objects/loading_context';
import { getPermissions } from '../utils/helper';
import DashboardLayout from '../components/dashboard';
import { BuildingOffice2Icon, BuildingStorefrontIcon, IdentificationIcon, PresentationChartBarIcon, RectangleGroupIcon, UsersIcon } from '@heroicons/react/24/outline';
import CompanySetting from '../components/setting/company';
import CashierSetting from '../components/setting/cashier';
import BusinessSetting from '../components/setting/business';
import AccountSetting from '../components/setting/account';
import ContactSetting from '../components/setting/contact';
import UserSetting from '../components/setting/user';

interface SettingPageProps {}

const SettingPage: FC<SettingPageProps> = ({}) => {
    const [mounted, setMounted] = useState(false);
    let { isLoading, setIsLoading } = useContext(LoadingContext);
    const [selectedMenu, setSelectedMenu] = useState("cashier");
    const [permissions, setPermissions] = useState<string[]>([]);
  
    useEffect(() => {
      setMounted(true)
  
    }, []);
  
    useEffect(() => {
      if (!mounted) return
      getPermissions().then(v => setPermissions(v))
    }, [mounted]);
  
  
    
  
  
    return (
      <DashboardLayout  permission="read_company">
        <div className="p-6 flex flex-col" style={{ width: "calc(100vw - 8rem)" }}>
          <h1 className="text-4xl">Pengaturan </h1>
          <div className=" tab-wrapper">
            {/* <div onClick={() => setSelectedMenu("company")} className={`tab-setting ${selectedMenu == "company" && 'active'}`}>
              <div className="flex items-center justify-center">
                <BuildingOffice2Icon className="w-4 mr-2" />Perusahaan
              </div>
            </div> */}
            <div onClick={() => setSelectedMenu("cashier")} className={`tab-setting ${selectedMenu == "cashier" && 'active'}`}>
              <div className="flex items-center justify-center">
                <BuildingStorefrontIcon className="w-4 mr-2" />POS
              </div>
            </div>
            {/* <div onClick={() => setSelectedMenu("business")} className={`tab-setting ${selectedMenu == "business" && 'active'}`}>
              <div className="flex items-center justify-center">
                <PresentationChartBarIcon className="w-4 mr-2" />Bisnis
              </div>
            </div>
            <div onClick={() => setSelectedMenu("account")} className={`tab-setting ${selectedMenu == "account" && 'active'}`}>
              <div className="flex items-center justify-center">
                <RectangleGroupIcon className="w-4 mr-2" />Akun
              </div>
            </div> */}
            <div onClick={() => setSelectedMenu("contact")} className={`tab-setting ${selectedMenu == "contact" && 'active'}`}>
              <div className="flex items-center justify-center">
                <IdentificationIcon className="w-4 mr-2" />Kontak
              </div>
            </div>
            {/* <div onClick={() => setSelectedMenu("user")} className={`tab-setting ${selectedMenu == "user" && 'active'}`}>
              <div className="flex items-center justify-center">
                <UsersIcon className="w-4 mr-2" />User & Hak Akses
              </div>
            </div> */}
  
          </div>
          <div className=" ">
            {/* {selectedMenu == "company" && <CompanySetting permissions={permissions} onUpdate={() => {
  
            }} />} */}
            {selectedMenu == "cashier" && <CashierSetting />}
            {/* {selectedMenu == "business" && <BusinessSetting />} */}
            {/* {selectedMenu == "account" && <AccountSetting />} */}
            {selectedMenu == "contact" && <ContactSetting />}
            {/* {selectedMenu == "user" && <UserSetting />} */}
          </div>
  
        </div>
      </DashboardLayout>
    )
}
export default SettingPage;