import { LoginGooleReq, LoginReq } from "../model/login";
import { customFetch } from "../utils/helper";

export let postLogin = async (req: LoginReq) => {
    return await customFetch("Login", {
        method: "POST",
        body: JSON.stringify(req)
    })
}
export let postLoginGoogle = async (req: LoginGooleReq) => {
    return await customFetch("LoginWebWithGoogle", {
        method: "POST",
        body: JSON.stringify(req)
    })
}