import type { FC } from 'react';

interface BadgeProps {
    label: string
    color?: string
    className?: string
    onClick?: () => void
}

const Badge: FC<BadgeProps> = ({label, color = 'red', className, onClick}) => {
        return (<span onClick={onClick} className={`inline-flex items-center rounded-md bg-${color}-50 px-1.5  py-0.5 text-xs font-medium text-${color}-600 ring-1 ring-inset ring-${color}-500/10 ${className}`}>{label}</span>);
}
export default Badge;
