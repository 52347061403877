import { ChevronLeftIcon } from '@heroicons/react/24/outline'
export default function Back({ onClick, className }: {
    onClick: () => void,
    className?: string
}) {
    return (<button onClick={onClick} className={`w-10 h-10 text-gray-500  hover:shadow-lg flex justify-center items-center p-2 bg-white rounded-lg ${className}`}>
        <ChevronLeftIcon
            className=" h-4 w-4"
            aria-hidden="true" /> 
    </button>)
}