import { DiscountReq } from "../model/discount";
import { PaginationReq } from "../model/pagination";
import { customFetch } from "../utils/helper";

export let getDiscounts = async (pagination: PaginationReq) => {
    var params: Record<string, string> = {
        page: pagination.page.toString(),
        limit: pagination.limit.toString(),
        search: pagination.search ?? "",

    };

    if (pagination.type) {
        params["type"] = pagination.type
    }

    return await customFetch(`Discount/?${new URLSearchParams(params)}`)
}
export let deleteDiscount = async (uuid: string) => {
    return await customFetch(`Discount/${uuid}`, {
        method: "DELETE"
    })
}
export let addDiscont = async (req: DiscountReq) => {
    return await customFetch(`Discount/`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}