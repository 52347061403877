import { MerchantSale } from '../model/pos';
import { useEffect, useState, type FC } from 'react';
import PDFView from './pdf_view';
import { View, StyleSheet, Text, Image } from '@react-pdf/renderer';
import { getSelectedMerchant, randomStr } from '../utils/helper';
import { Merchant } from '../model/merchant';
import moment from 'moment';
import { money } from '../utils/number';
import QRCode from 'qrcode';

interface PrintReceiptProps {
    sale: MerchantSale
}

const PrintReceipt: FC<PrintReceiptProps> = ({
    sale
}) => {
    const [merchant, setMerchant] = useState<Merchant | null>(null);
    const [qrString, setQrString] = useState("");

    useEffect(() => {
        getData()
    }, []);

    const getData = async () => {
        setMerchant(await getSelectedMerchant())
    }
    useEffect(() => {
        if (merchant && merchant.message_to_qr) {
            getQRMessage(merchant.message_to_qr)
                .then(e => {
                    setQrString(e)
                })
        }
    }, [merchant]);
    const styles = StyleSheet.create({
        defaultFont: {
            fontFamily: 'dot',
            letterSpacing: 0.8,
            fontSize: 6
        },
        header: {
            fontFamily: 'dot',
            letterSpacing: 0.8,
            fontSize: 8
        },
        cellStyle: {
            border: 'solid',
            borderWidth: 0.4,
            paddingVertical: 4,
            paddingHorizontal: 8,
            borderColor: "black"
        }
    });
    const inlineData = (label: string, data: string) => (<View key={randomStr(3)} style={{ display: 'flex', flexDirection: "row", paddingBottom: 2, marginBottom: 1.5, justifyContent: 'space-between' }}>
        <View style={{ flexGrow: 1, flexShrink: 1, flexBasis: "0%", marginRight: 5 }}>
            <Text style={{ ...styles.defaultFont }}>{label}</Text>
        </View>
        <View style={{ minWidth: 40, display: 'flex', alignItems: 'flex-end' }}>
            <Text style={{ ...styles.defaultFont, textAlign: 'center' }}>{data}</Text>
        </View>
    </View>)

    const getQRMessage = async (msg: string): Promise<string> => {
        return await new Promise((resolve, reject) => {
            QRCode.toDataURL(msg, (err, code) => {
                if (err) {
                    reject(reject);
                    return;
                }
                console.log(code)
                resolve(code);
            });
        });
    }
    return (merchant && <PDFView title={'PRINT RECEIPT'} print={false} download={false} marginTop='5mm' >
        <View style={{ width: "58mm", minHeight: "100mm", border: 0, paddingHorizontal: "3mm" }}>
            <Text style={{ ...styles.header, textAlign: 'center' }}>{merchant.name}</Text>
            <Text style={{ ...styles.defaultFont, textAlign: 'center', marginBottom: 10 }}>{merchant.address} {merchant.phone}</Text>
            <View style={{ display: 'flex', flexDirection: "column" }} >
                <Text style={{ ...styles.defaultFont, textAlign: 'left' }}>Tgl: {moment(sale.date).format("DD/MM/YYYY HH:mm")} </Text>
                <Text style={{ ...styles.defaultFont, textAlign: 'left' }}>Kasir: {sale.cashier_name} </Text>
                {sale.customer_name && <Text style={{ ...styles.defaultFont, textAlign: 'left' }}>Cust: {sale.customer_name} </Text>}
                <View style={{ display: 'flex', flexDirection: "row", paddingBottom: 5, borderBottom: 0.2, marginBottom: 5 }}></View>
                {sale.items.map(e => inlineData(`${e.description} x${money(e.qty)}`, `${money(e.amount)}`))}
                <View style={{ display: 'flex', flexDirection: "row", paddingBottom: 5, borderBottom: 0.2, marginBottom: 5 }}></View>
                {inlineData(`Subtotal`, `${money(sale.subtotal)}`)}
                {sale.discount_total > 0 && inlineData(`Disk.`, `${money(sale.discount_total)}`)}
                {sale.tax_amount > 0 && inlineData(`${sale.tax_name}`, `${money(sale.tax_amount)}`)}
                {inlineData(`TOTAL`, `${money(sale.grand_total)}`)}
                <View style={{ display: 'flex', flexDirection: "row", paddingBottom: 5, marginBottom: 5 }}></View>

                <Text style={{ ...styles.defaultFont, textAlign: 'center' }}>{merchant.message}</Text>
                {qrString != "" &&
                    <View style={{ display: 'flex', flexDirection: "column", paddingBottom: 5, marginBottom: 5, justifyContent: 'center', alignItems: 'center' }}>
                        <Image source={qrString} style={{ width: 48, textAlign: 'center' }} />
                    </View>
                }

            </View>
        </View>

    </PDFView>);
}
export default PrintReceipt;