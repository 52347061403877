import { Pagination } from '../../model/pagination';
import { MerchantSaleReport } from '../../model/pos';
import { getReportDownload, getReports } from '../../repositories/pos';
import moment from 'moment';
import { useEffect, useState, type FC } from 'react';
import DateRangePicker, { DateRange } from 'rsuite/esm/DateRangePicker';
import CustomTable from '../custom_table';
import Moment from 'react-moment';
import { money } from '../../utils/number';
import { EyeIcon, PrinterIcon } from '@heroicons/react/24/outline';
import { FaDownload } from 'react-icons/fa6';
import { FaCloudDownloadAlt } from 'react-icons/fa';
import saveAs from 'file-saver';
import ModalCustom from '../modals/modal_custom';
import InlineForm from '../inline_form';
import { getStorageDateRange } from '../../utils/helper';

interface PosReportsProps { }

const PosReports: FC<PosReportsProps> = ({ }) => {
    const [dateRange, setDateRange] = useState<DateRange>([moment().subtract("1", "days").toDate(), moment().toDate()]);
    const [mounted, setMounted] = useState(false);
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(20);
    const [pagination, setPagination] = useState<Pagination | null>(null);
    const [reports, setReports] = useState<MerchantSaleReport[]>([]);
    const [selectedReports, setSelectedReports] = useState<MerchantSaleReport[]>([]);
    const [selectReport, setSelectReport] = useState<MerchantSaleReport | null>(null);
    const [modalReportDetail, setModalReportDetail] = useState(false);

    useEffect(() => {
        getStorageDateRange()
        .then(v => {
            if (!mounted && v) {
                setDateRange([moment(v[0]).toDate(), moment(v[1]).toDate()])
            }
            setMounted(true)

        })
        setMounted(true)
        
    }, []);

    useEffect(() => {
        if (!mounted) return
        getAllReports()
    }, [mounted]);

    const getAllReports = async () => {
        getReports(dateRange, { page, limit, search })
            .then(v => v.json())
            .then(v => {
                setReports(v.data)
                setPagination(v.meta)
            })
    }
    return (<div className="flex-1 mb-5col-span-1 mt-5 rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer">
        <div className='flex justify-end mb-4  mt-5 '>
            <DateRangePicker placement="bottomEnd" value={dateRange} block format="dd/MM/yyyy" onOk={(val: DateRange) => {
                setDateRange(val)
            }} />

            {/* {renderMenu()} */}

        </div>
        <CustomTable
            pagination
            total={pagination?.total_records}
            limit={limit}
            activePage={page}
            setActivePage={(val) => {
                setPage(val)
            }}
            changeLimit={(val) => setLimit(val)}
            limitPaginations={[10, 20, 50, 100, 1000]}
            headers={["#", "Tgl", "Keterangan", "Total", ""]} headerClasses={["w-10", "w-32", "", "text-right", "w-10"]} datasets={reports.map(e => {
                return {
                    cells: [
                        { data: ((page - 1) * limit) + (reports.indexOf(e) + 1) },
                        {
                            data: <div className='flex flex-col'>
                                <Moment format='DD MMM YYYY'>{e.date}</Moment>
                                <Moment className='text-xs' format='HH:mm'>{e.date}</Moment>
                            </div>
                        },
                        { data: e.title },
                        { data: money(e.grand_total), className: "text-right" },
                        {
                            data: <div className='flex'>
                                <EyeIcon className='w-5 text-blue-300 hover:text-blue-600 mr-2' onClick={() => {
                                    setSelectReport(e)
                                    setTimeout(() => {
                                        setModalReportDetail(true)
                                    }, 300);
                                }} />
                                <FaCloudDownloadAlt size={30} className='w-5 text-blue-300 hover:text-blue-600 mr-2' onClick={() => {
                                    getReportDownload(e.uuid)
                                        .then(v => v.blob())
                                        .then(v => {
                                            saveAs(v, `${e.title}.xlsx`)
                                        })
                                }} />
                                <PrinterIcon className='w-5 text-blue-300 hover:text-blue-600 mr-2' onClick={() => {
                                   window.open(`/cashier/report/${e.uuid}/print`)
                                }} />
                            </div>
                        }


                    ],
                    className: "last:border-0 hover:bg-gray-50"
                }
            })} />
    <ModalCustom title={selectReport?.title ?? "Detail Laporan"} isOpen={modalReportDetail} setIsOpen={setModalReportDetail}  >
    <CustomTable className='mb-4 mt-4' headers={["No", "Kode", "Total", "Pembayaran"]} headerClasses={["", "", "text-right"]}
                footer={[
                    {
                        cells: [{ data: "" },
                        { data: <div className=' font-semibold'>Total Penjualan CASH</div> },
                        { data: money(selectReport?.sales.filter(e => e.payment_method == "CASH").map(e => e.grand_total).reduce((a, b) => a + b, 0)), className: "text-right" },
                        { data: "" }]
                    },
                    {
                        cells: [{ data: "" },
                        { data: <div className=' font-semibold'>Total Penjualan NON CASH</div> },
                        { data: money(selectReport?.sales.filter(e => e.payment_method != "CASH").map(e => e.grand_total).reduce((a, b) => a + b, 0)), className: "text-right" },
                        { data: "" }]
                    },
                    {
                        cells: [{ data: "" },
                        { data: <div className=' font-semibold'>Total Penjualan</div> },
                        { data: money(selectReport?.sales.map(e => e.grand_total).reduce((a, b) => a + b, 0)), className: "text-right" },
                        { data: "" }]
                    },
                ]}
                datasets={(selectReport?.sales??[]).map(e => {
                    return {
                        cells: [
                            { data: (selectReport?.sales??[]).indexOf(e) + 1 },
                            {
                                data: <div className=' hover:font-semibold' onClick={() => {
                                    window.open(`/pos/${e.uuid}/receipt`)
                                }}>{e.code}</div>, className: " cursor-pointer"
                            },
                            { data: money(e.grand_total), className: "text-right" },
                            { data: e.payment_method }
                        ],
                        className: "hover:bg-gray-50"
                    }
                })} />
    </ModalCustom>
    </div>);
}
export default PosReports;