import type { FC } from 'react';
import { CSSObject } from 'styled-components';

interface InlineFormProps {
    children: React.ReactNode;
    title?: String;
    subtitle?: String;
    className?: string
    style?: CSSObject
}

const InlineForm: FC<InlineFormProps> = ({
    children,
    title,
    subtitle,
    className,
    style,
}) => {
    return (<div className={`flex items-center mb-6 ${className}`} style={style}>
        <div className="w-1/3">
            <label
                className="block text-gray-900 mb-0 pr-4"
            >
                <div className='flex flex-col'>
                    {title}
                    {subtitle}
                </div>
            </label>
        </div>
        <div className="w-2/3">
            {children}
        </div>
    </div>);
}
export default InlineForm;