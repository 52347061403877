import { useParams } from 'react-router-dom';
import DashboardLayout from '../components/dashboard';
import PrintReceipt from '../components/print_receipt';
import { MerchantSale } from '../model/pos';
import { getPosSaleDetail } from '../repositories/pos';
import { useEffect, useState, type FC } from 'react';

interface PosReportReceiptProps {}

const PosReportReceipt: FC<PosReportReceiptProps> = ({}) => {
    const { receiptId } = useParams()
    const [mounted, setMounted] = useState(false);
    const [sale, setSale] = useState<MerchantSale | null>(null);

    useEffect(() => {
        setMounted(true)
    }, []);

    useEffect(() => {
        if (!mounted) return
        getPosSaleDetail(receiptId!)   
            .then(v => v.json())
            .then(v => setSale(v.data))
    }, [mounted]);

    return (sale &&
        <PrintReceipt sale={sale!} />
    
    );
}
export default PosReportReceipt;