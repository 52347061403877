import DashboardLayout from '../components/dashboard';
import PosReports from '../components/pos_report/report';
import PosSales from '../components/pos_report/sales';
import { BuildingOffice2Icon } from '@heroicons/react/24/outline';
import { useState, type FC } from 'react';

interface CashierReportProps {}

const CashierReport: FC<CashierReportProps> = ({}) => {
    const [selectedMenu, setSelectedMenu] = useState("sale");
    return (
        <DashboardLayout permission='cashier_user'>
            <div className="p-6 flex flex-col" >
                <h1 className="text-4xl">Laporan Penjualan </h1>
                <div className=" tab-wrapper" style={{ backgroundColor: 'transparent'}}>
                    <div onClick={() => setSelectedMenu("sale")} className={`tab-setting bg-gray-200 ${selectedMenu == "sale" && 'active'}`}>
                        <div className="flex items-center justify-center">
                            Penjualan
                        </div>
                    </div>
                    <div onClick={() => setSelectedMenu("report")} className={`tab-setting bg-gray-200 ${selectedMenu == "report" && 'active'}`}>
                        <div className="flex items-center justify-center">
                            Laporan
                        </div>
                    </div>
                </div>
                <div>
                    {selectedMenu == "sale" && <PosSales />}
                    {selectedMenu == "report" && <PosReports />}
                </div>
            </div>
        </DashboardLayout>
    );
}
export default CashierReport;