import { MerchantAccountReq, MerchantProductReq, MerchantReq } from "../model/merchant"
import { SELECTED_MERCHANT_ID } from "../objects/constants"
import { NullString } from "../objects/null_string"
import { asyncLocalStorage, customFetch, getCompany } from "../utils/helper"

export let getMerchants = async () => {
    return await customFetch("My/Merchants")
}
export let getMerchantDetail = async () => {
    const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID)
    return await customFetch(`Merchant/${merchantId}`)
}
export let getMerchantAccounts = async () => {
    const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID)
    return await customFetch(`Merchant/${merchantId}/Account`)
}
export let getMerchantMachines = async () => {
    const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID)
    return await customFetch(`Merchant/${merchantId}/Machine`)
}
export let getMerchantProductCategories = async () => {
    const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID)
    return await customFetch(`Merchant/${merchantId}/ProductCategory`)
}
export let addMerchantProductCategory = async ({ product_category_id }: { product_category_id: NullString }) => {
    const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID)
    return await customFetch(`Merchant/${merchantId}/AddProductCategory`, {
        method: "POST",
        body: JSON.stringify({ product_category_id })
    })
}
export let deleteMerchantProductCategory = async (uuid: string) => {
    const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID)
    return await customFetch(`Merchant/${merchantId}/ProductCategory/${uuid}`, {
        method: "DELETE",
    })
}

export let getMerchantProducts = async () => {
    const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID)
    return await customFetch(`Merchant/${merchantId}/Product`)
}
export let addMerchantProduct = async (req: MerchantProductReq) => {
    const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID)
    return await customFetch(`Merchant/${merchantId}/AddProduct`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}
export let deleteMerchantProduct = async (uuid: string) => {
    const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID)
    return await customFetch(`Merchant/${merchantId}/Product/${uuid}`, {
        method: "DELETE",
    })
}
export let pinnedMerchantProduct = async (uuid: string) => {
    const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID)
    return await customFetch(`Merchant/${merchantId}/Product/${uuid}/Pinned`, {
        method: "POST",
    })
}

export let updateMerchant = async (req: MerchantReq) => {
    const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID)
    return await customFetch(`Merchant/${merchantId}`, {
        method: "PUT",
        body: JSON.stringify(req)
    })
}
export let addMerchantAccount = async (req: MerchantAccountReq) => {
    const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID)
    return await customFetch(`Merchant/${merchantId}/AddAccount`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}
export let addMachine = async (req: { name: string, deposit: number }) => {
    const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID)
    return await customFetch(`Merchant/${merchantId}/AddMachine`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}
export let updateMachine = async (uuid: string, req: { name: string, deposit: number }) => {
    const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID)
    return await customFetch(`Merchant/${merchantId}/Machine/${uuid}`, {
        method: "PUT",
        body: JSON.stringify(req)
    })
}
export let deleteMachine = async (uuid: string) => {
    const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID)
    return await customFetch(`Merchant/${merchantId}/Machine/${uuid}`, {
        method: "DELETE",
    })
}

export let deleteMerchantAccount = async (uuid: string) => {
    const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID)
    return await customFetch(`Merchant/${merchantId}/Account/${uuid}`, {
        method: "DELETE",
    })
}

export let getMerchantPriceCategories = async () => {
    const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID)
    return await customFetch(`Merchant/${merchantId}/PriceCategory`)
}
export let addMerchantPriceCategory = async ({ price_category_id }: { price_category_id: NullString }) => {
    const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID)
    return await customFetch(`Merchant/${merchantId}/AddPriceCategory`, {
        method: "POST",
        body: JSON.stringify({ price_category_id })
    })
}
export let deleteMerchantPriceCategory = async (uuid: string) => {
    const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID)
    return await customFetch(`Merchant/${merchantId}/PriceCategory/${uuid}`, {
        method: "DELETE",
    })
}

export let getMerchantServices = async () => {
    const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID)
    return await customFetch(`Merchant/${merchantId}/Service`)
}
export let addMerchantService = async (req: {
    service_id: NullString
    is_pinned: boolean
}) => {
    const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID)
    return await customFetch(`Merchant/${merchantId}/AddService`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}
export let deleteMerchantService = async (uuid: string) => {
    const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID)
    return await customFetch(`Merchant/${merchantId}/Service/${uuid}`, {
        method: "DELETE",
    })
}


export let getMerchantStores = async () => {
    const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID)
    return await customFetch(`Merchant/${merchantId}/Store`)
}
export let addMerchantStore = async ({ store_id }: { store_id: NullString }) => {
    const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID)
    return await customFetch(`Merchant/${merchantId}/AddStore`, {
        method: "POST",
        body: JSON.stringify({ store_id })
    })
}
export let deleteMerchantStore = async (uuid: string) => {
    const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID)
    return await customFetch(`Merchant/${merchantId}/Store/${uuid}`, {
        method: "DELETE",
    })
}

export let getMerchantUsers = async () => {
    const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID)
    return await customFetch(`Merchant/${merchantId}/User`)
}
export let addMerchantUser = async ({ cashier_id }: { cashier_id: NullString }) => {
    const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID)
    return await customFetch(`Merchant/${merchantId}/AddUser`, {
        method: "POST",
        body: JSON.stringify({ cashier_id })
    })
}
export let deleteMerchantUser = async (uuid: string) => {
    const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID)
    return await customFetch(`Merchant/${merchantId}/User/${uuid}`, {
        method: "DELETE",
    })
}

export let getMerchantTaxes = async () => {
    const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID)
    return await customFetch(`Merchant/${merchantId}/Tax`)
}
export let addMerchantTax = async ({ tax_id }: { tax_id: NullString }) => {
    const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID)
    return await customFetch(`Merchant/${merchantId}/AddTax`, {
        method: "POST",
        body: JSON.stringify({ tax_id })
    })
}
export let deleteMerchantTax = async (uuid: string) => {
    const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID)
    return await customFetch(`Merchant/${merchantId}/Tax/${uuid}`, {
        method: "DELETE",
    })
}

export let getMerchantDiscounts = async () => {
    const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID)
    return await customFetch(`Merchant/${merchantId}/Discount`)
}
export let addMerchantDiscount = async ({ discount_id }: { discount_id: NullString }) => {
    const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID)
    return await customFetch(`Merchant/${merchantId}/AddDiscount`, {
        method: "POST",
        body: JSON.stringify({ discount_id })
    })
}
export let deleteMerchantDiscount = async (uuid: string) => {
    const merchantId = await asyncLocalStorage.getItem(SELECTED_MERCHANT_ID)
    return await customFetch(`Merchant/${merchantId}/Discount/${uuid}`, {
        method: "DELETE",
    })
}