import { Link } from "react-router-dom";

export default function MenuIcon({ active, inactive, isActive, link, name }: {
    active: string;
    inactive: string;
    isActive: boolean;
    link: string;
    name: string;
}) {
    return (
    <Link to={link}>
    <div className="flex flex-col justify-center items-center">
        {isActive ? <img className="w-8 h-8 invert mb-2" src={active} /> : <img className="w-8 h-8 invert mb-2" src={inactive} style={{ opacity: 0.5 }} />}
        <p className=" text-xs text-white" style={{opacity: isActive ? 1 : 0.5 }}>{name}</p>
    </div>
    </Link>)
}