import { initials } from "../utils/helper";
import { CSSProperties } from "react";

export default function Avatar({ name, url, size, marginRight, style}: {
    name: string;
    url: string;
    size?: number;
    marginRight?: number;
    style?: CSSProperties 
}) {
    return (
        url.length == 0 ? <div className={`relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600 mr-4`} style={{width: size, height: size, marginRight: marginRight, ...style}}>
            <span className="font-medium text-gray-600 dark:text-gray-300 text-center text-sm">{initials(name)}</span>
        </div> :
            <img className={`w-10 h-10 rounded-full mr-4 object-cover`} src={url} alt={name} style={{width: size, height: size, marginRight: marginRight}}/>
    )
}