import type { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import NotFound from '../pages/NotFound';
import Login from '../pages/Login';

interface PublicRoutesProps {}

const PublicRoutes: FC<PublicRoutesProps> = ({}) => {
        return (<Routes>
            <Route path='login' element={<Login />} />
            <Route path='*' element={<Navigate to='/login' replace />} />
        </Routes>);
}
export default PublicRoutes;