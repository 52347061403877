import { PaginationReq } from "../model/pagination";
import { TaxReq } from "../model/tax";
import { customFetch } from "../utils/helper";


export let getTaxes = async (pagination: PaginationReq) => {
    var params: Record<string, string> = {
        page: pagination.page.toString(),
        limit: pagination.limit.toString(),
        search: pagination.search ?? "",

    };

    if (pagination.type) {
        params["type"] = pagination.type
    }

    return await customFetch(`Tax/?${new URLSearchParams(params)}`)
}
export let getTaxDetail = async (uuid: string) => {
 
    return await customFetch(`Tax/${uuid}`)
}

export let deleteTax = async (uuid: string) => {
    return await customFetch(`Tax/${uuid}`, {
        method: "DELETE",
    })
}
export let addTax = async (req: TaxReq) => {
    return await customFetch(`Tax/`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}
