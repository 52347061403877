import PDFView from '../components/pdf_view';
import { Merchant } from '../model/merchant';
import { MerchantSaleReport } from '../model/pos';
import { getMerchantDetail } from '../repositories/merchant';
import { getReportDetail } from '../repositories/pos';
import { useEffect, useState, type FC } from 'react';
import { Font, StyleSheet, View, Text } from '@react-pdf/renderer';
import moment from 'moment';
import { money } from '../utils/number';
import { useParams } from 'react-router-dom';

interface CashierReportPrintProps {}

const CashierReportPrint: FC<CashierReportPrintProps> = ({}) => {
    const { cashierReportId } = useParams()
    const [mounted, setMounted] = useState(false);
    const [merchant, setMerchant] = useState<Merchant | null>(null);
    const [report, setReport] = useState<MerchantSaleReport | null>(null);

    useEffect(() => {
        setMounted(true)
    }, []);

    useEffect(() => {
        if (!mounted) return
        getDetail()
        getMerchantDetail()
            .then(v => v.json())
            .then(v => setMerchant(v.data))
    }, [mounted]);

    const getDetail = () => {
        getReportDetail(cashierReportId!)
            .then(v => v.json())
            .then(v => setReport(v.data))
    }

    const styles = StyleSheet.create({
        defaultFont: {
            fontFamily: 'dot',
            letterSpacing: 0.8,
            fontSize: 7
        },
        cellStyle: {
            border: 'solid',
            borderWidth: 0.4,
            paddingVertical: 2,
            paddingHorizontal: 6,
            borderColor: "black"
        }
    });

    return ( merchant &&
        <PDFView orientation='landscape' title={report?.title ?? "Detail Laporan"} print={false} download={false} >
            <View style={{ display: 'flex', flexDirection: 'column', paddingHorizontal: 20, marginBottom: 10, paddingBottom: 5 }}>
                <Text style={{ ...styles.defaultFont, fontSize: 14, textAlign: 'left', }}>{merchant!.name}</Text>
                <Text style={{ ...styles.defaultFont, fontSize: 10, textAlign: 'left' }}>{merchant?.address ?? ""} {merchant?.phone}</Text>
            </View>

            <View style={{ display: 'flex', flexDirection: 'column', paddingHorizontal: 20, marginBottom: 5, paddingBottom: 5 }}>
                <Text style={{ ...styles.defaultFont, fontSize: 9 }}>Tgl: {moment(report?.date).locale("id").format("DD MMM YYYY HH:mm")}</Text>
                <Text style={{ ...styles.defaultFont, fontSize: 9 }}>Akun Penjualan: {report?.account_source_name}</Text>
                <Text style={{ ...styles.defaultFont, fontSize: 9 }}>Gudang: {report?.store_name}</Text>
                <Text style={{ ...styles.defaultFont, fontSize: 9 }}>Total Penjualan: {money(report?.grand_total)}</Text>
                <View style={{ borderBottomStyle: "solid", borderBottomWidth: 0.4, borderBottomColor: "black", marginTop: 10, paddingBottom: 5, marginBottom: 2 }}></View>
            </View>
            <View style={{ display: 'flex', flexDirection: 'column', paddingHorizontal: 20, marginBottom: 0, paddingBottom: 5 }}>
                <Text style={{ ...styles.defaultFont, fontSize: 10 }}>Detail Penjualan</Text>
            </View>

            <View style={{ display: 'flex', flexDirection: 'column', paddingHorizontal: 20, marginBottom: 10 }}>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View style={{ ...styles.cellStyle, width: 50, borderLeft: "none" }}><Text style={{ ...styles.defaultFont, fontSize: 8, textAlign: 'center' }}>No</Text></View>
                    <View style={{ ...styles.cellStyle, width: 60, }}><Text style={{ ...styles.defaultFont, fontSize: 8, textAlign: 'center' }}>Tgl</Text></View>
                    <View style={{ ...styles.cellStyle, width: 80, }}><Text style={{ ...styles.defaultFont, fontSize: 8, textAlign: 'left' }}>Konsumen</Text></View>
                    <View style={{ ...styles.cellStyle, width: 80, }}><Text style={{ ...styles.defaultFont, fontSize: 8, textAlign: 'left' }}>Akun Kas</Text></View>
                    <View style={{ ...styles.cellStyle, width: 80, }}><Text style={{ ...styles.defaultFont, fontSize: 8, textAlign: 'left' }}>Metode Pemb.</Text></View>
                    <View style={{ ...styles.cellStyle, width: 80, }}><Text style={{ ...styles.defaultFont, fontSize: 8, textAlign: 'left' }}>Layanan</Text></View>
                    <View style={{ ...styles.cellStyle, width: 80, }}><Text style={{ ...styles.defaultFont, fontSize: 8, textAlign: 'right' }}>S. Total</Text></View>
                    <View style={{ ...styles.cellStyle, width: 80, }}><Text style={{ ...styles.defaultFont, fontSize: 8, textAlign: 'right' }}>Disk.</Text></View>
                    <View style={{ ...styles.cellStyle, width: 80, }}><Text style={{ ...styles.defaultFont, fontSize: 8, textAlign: 'center' }}>Pajak</Text></View>
                    <View style={{ ...styles.cellStyle, width: 80, }}><Text style={{ ...styles.defaultFont, fontSize: 8, textAlign: 'right' }}>Total</Text></View>
                    <View style={{ ...styles.cellStyle, width: 80, }}><Text style={{ ...styles.defaultFont, fontSize: 8, textAlign: 'right' }}>Pembayaran</Text></View>
                    <View style={{ ...styles.cellStyle, width: 80, borderRight: "none" }}><Text style={{ ...styles.defaultFont, fontSize: 8, textAlign: 'right' }}>Kemb.</Text></View>
                </View>
                {report?.sales.map(e =>   <View key={e.uuid} style={{ display: 'flex', flexDirection: 'row' }}>
                    <View style={{ ...styles.cellStyle, width: 50, borderLeft: "none" }}><Text style={{ ...styles.defaultFont, textAlign: 'center' }}>{money(e.order_number)}</Text></View>
                    <View style={{ ...styles.cellStyle, width: 60, }}><Text style={{ ...styles.defaultFont, textAlign: 'center' }}>{moment(e.date).format("DD/MM/YYYY")}</Text></View>
                    <View style={{ ...styles.cellStyle, width: 80, }}><Text style={{ ...styles.defaultFont, textAlign: 'left' }}>{e.customer_name}</Text></View>
                    <View style={{ ...styles.cellStyle, width: 80, }}><Text style={{ ...styles.defaultFont, textAlign: 'left' }}>{e.account_destination_name}</Text></View>
                    <View style={{ ...styles.cellStyle, width: 80, }}><Text style={{ ...styles.defaultFont, textAlign: 'left' }}>{e.payment_method}</Text></View>
                    <View style={{ ...styles.cellStyle, width: 80, }}><Text style={{ ...styles.defaultFont, textAlign: 'left' }}>{e.payment_provider}</Text></View>
                    <View style={{ ...styles.cellStyle, width: 80, }}><Text style={{ ...styles.defaultFont, textAlign: 'right' }}>{money(e.subtotal)}</Text></View>
                    <View style={{ ...styles.cellStyle, width: 80, }}><Text style={{ ...styles.defaultFont, textAlign: 'right' }}>{money(e.discount_total)}</Text></View>
                    <View style={{ ...styles.cellStyle, width: 80, }}><Text style={{ ...styles.defaultFont, textAlign: 'center' }}>{e.tax_name}</Text></View>
                    <View style={{ ...styles.cellStyle, width: 80, }}><Text style={{ ...styles.defaultFont, textAlign: 'right' }}>{money(e.grand_total)}</Text></View>
                    <View style={{ ...styles.cellStyle, width: 80, }}><Text style={{ ...styles.defaultFont, textAlign: 'right' }}>{money(e.payment_amount)}</Text></View>
                    <View style={{ ...styles.cellStyle, width: 80, borderRight: "none" }}><Text style={{ ...styles.defaultFont, textAlign: 'right' }}>{money(e.change)}</Text></View>
                </View>)}
            </View>
        </PDFView>
    );
}
export default CashierReportPrint;